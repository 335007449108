const ReportHeaders = (props) => {
    const showInsight = props.showInsight
    let headers_names = Object.entries(props.header_names)     
    return (
        <tr>
            {/* <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select All</p></th> */}

            {headers_names.map((each_item, index)=>{
                if(each_item[0]==='offer' || each_item[0]==='source_medium'){
                    // console.log("------>", showInsight, each_item[0])
                    if(showInsight && each_item[0]==='source_medium'){
                        return  <th key={index}>{each_item[1]}</th>
                    }else if(!showInsight && each_item[0]==='offer'){
                        return  <th key={index}>{each_item[1]}</th>
                    }
                }else{
                    return  <th key={index}>{each_item[1]}</th>
                }
            })}
        </tr>
    )
}

export default ReportHeaders;