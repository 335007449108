import classes from "./Tools.module.scss"

// packages
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector"

// components
import CustomButton from "../Customs/CustomButton/CustomButton"

import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

// icons 
import edit from '../../assets/icons/pencil.svg'
import LoadingTable from "../LoadingTable/LoadingTable";

import axios from 'axios'

// import CustomSelect from '../Customs/CustomSelect/CustomSelect'
// import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';
// import goback from '../../assets/icons/go_back.svg'
// import { Link } from "react-router-dom";



const Tracking = () => {
    const tools_names = useSelector(state => state.ui.language.values.toolsContent)
    const btn_texts = useSelector(state=>state.ui.language.values.btn_texts)
    const API_URL = process.env.REACT_APP_API_URL

    // states
    const [showEditCodePopUp, setShowEditCodePopUp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [failLoading, setFailLoading] = useState(false)
    const [isLoadding, setIsLoadding] = useState(true)
    const [editCodeType, setEditCodeType] = useState('')
    const headCodeAreaRef = useRef(null)
    const bodyCodeAreaRef = useRef(null)

    const [headCode, setHeadCode] = useState("")
    const [bodyCode, setBodyCode] = useState("")

    // functions
    const handleToggleEdit = (e) => {
        setShowEditCodePopUp(!showEditCodePopUp)
        const target_id = e.target.id
        console.log("handle toggle edit clicked :", e.target.id)

        if (target_id === "1"){
            setEditCodeType(tools_names.before_head_tag)

        }else if(target_id === "2"){
            setEditCodeType(tools_names.before_body_tag)
        }

    }

    const handleSaveCode = () => {
        console.log("handle save code clicked")

    }

    const getTrackingCode = () => {
        setIsLoadding(true)
        // setTimeout(()=>setIsLoadding(false), 5000)
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/tracking-code`,
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };

        axios.request(config)
        .then((response) => {
            setHeadCode(response.data.head_code)
            headCodeAreaRef.current.innerHTML = response.data.head_code
            setBodyCode(response.data.body_code)
            setIsLoadding(false)
        })
        .catch((error) => {
            console.log("Ajax error", error)
            setIsLoadding(false)
            if(error && error.response && error.response.status > 400 ){
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    useEffect(()=>{
        getTrackingCode()
    }, [])

    const onTextareaChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'headCode') {
            setHeadCode(value)
        }else if(name === 'bodyCode') {
            setBodyCode(value)
        }
    }

    const updateCode = (code, type)=>{
        setIsLoadding(true)
        // setTimeout(()=>setIsLoadding(false), 5000)
        // console.log("headCode", headCode, bodyCode, code,"--->", headCodeAreaRef.current)
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/tracking-code`,
            data: {
                "type": type,
                "code": (type==="headCode")?headCode:bodyCode
            },
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };
        axios.request(config)
        .then((response) => {
            // setHeadCode(response.data.headCode)
            // setBodyCode(response.data.bodyCode)
            setIsLoadding(false)
            getTrackingCode()
        })
        .catch((error) => {
            console.log("Ajax error", error)
            setIsLoadding(false)
            if(error && error.response && error.response.status > 400 ){
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    return (
        <>
            {/* edit shows */}
            {showEditCodePopUp && 
            <PopUpCard toggle={handleToggleEdit} header={tools_names.edit_code_title}>
                {loading && <CheckAnimation />}
                {failLoading && <FailAnimation />}

                <form className={classes.main_middle_edit_div}>
                    <div className={classes.middle_item_div}>
                        <div className={classes.middle_item_label_div}>
                            <label className={classes.middle_item_label}>
                                {editCodeType}
                            </label>
                            <img src={edit} alt='edit'/>
                        </div>
                        <div className={classes.middle_item_input_div}>
                            <textarea className={classes.middle_textarea} placeholder="" onChange={(e) => console.log(e)} required></textarea>
                        </div>
                    </div>

                    <div className={classes.edit_code_buttons}>
                        <div className={classes.customButton}>
                            <CustomButton color='green' name={btn_texts.save} onClick={handleSaveCode}/>
                        </div>
                    </div>
                </form>
            </PopUpCard>}


            {/* main components of tools page */}
            <div className={classes.container}>
                <div className={classes.main}>
                    <div className={classes.main_top} style={{zIndex:9991, padding:"1em 0em"}}>
                        <div className={classes.main_title}>
                            <div className="heading">{tools_names.title}{tools_names.tracking_tags}</div>
                        </div>
                    </div> 

                    {isLoadding &&
                        <LoadingTable/>
                    }
                    <div className={classes.tracking_main_middle}>
                        <div className={classes.main_middle_top}>
                            <div className={classes.main_middle_title}>
                                {tools_names.before_head_tag}
                            </div>
                            <div className={classes.main_middle_code_div}>
                                <div className={classes.main_middle_code}>
                                    <textarea ref={headCodeAreaRef} className={classes.textarea_code} name={"headCode"} onChange={onTextareaChange} defaultValue={headCode}></textarea>
                                    
                                    <div className={classes.main_middle_edit}>
                                        <CustomButton id="1" color='orange' name={"Update"} onClick={()=>updateCode(headCode, "headCode")} />
                                    </div>
                                </div>

                            </div>  
                        </div> 

                        <div className={classes.main_middle_bottom}>
                            <div className={classes.main_middle_title}>
                                {tools_names.before_body_tag}
                            </div> 
                            
                            <div className={classes.main_middle_code_div}>
                                <div className={classes.main_middle_code}>
                                    <textarea ref={bodyCodeAreaRef} className={classes.textarea_code} name={"bodyCode"} onChange={onTextareaChange} defaultValue={bodyCode} ></textarea>
                                    <div className={classes.main_middle_edit}>
                                        <CustomButton id="2" color='orange' value name={"Update"} onClick={()=>updateCode(bodyCode, "bodyCode")} />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div> 
        </>
    )
}

export default Tracking;