import classes from './Login.module.scss'
import register_classes from '../Register/Register.module.scss'

import email_icon from '../../assets/icons/email.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_green from '../../assets/icons/email_blue.svg'
import lock from '../../assets/icons/lock.svg'
import lock_red from '../../assets/icons/lock_red.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_green from '../../assets/icons/lock_green.svg'

import CustomInput1 from '../Customs/CustomInput1/CustomInput1'
import CustomButton from '../Customs/CustomButton/CustomButton'

import { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { uiActions } from '../..//store/ui-slice'

import { TimeOutHandler} from '../../utils/helper_functions'

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('')
    const [resetSuccess, setResetSuccess] = useState(false)
    const [passwordResetToeken, setPasswordResetToken] = useState(props.reset)
    const [password, setPassword] = useState("")
    
    const [emailStatus, setEmailStatus] = useState('pending')
    
    const [emailIcon, setEmailIcon] = useState(email_icon)
    const [errorMsg, setErrorMsg] = useState("")

    const dispatch = useDispatch()
    const navigate = useNavigate ()

    useEffect(()=>{
        document.title = "LinkVantage | Reset Password"
    }, [])

    const API_URL = process.env.REACT_APP_API_URL
    const passwordHandler = (e) => {
        setErrorMsg("")
        setPassword(e.target.value)
    }
    const submitHandler = async (e) => {
        e.preventDefault()
        setErrorMsg("")
        if(emailStatus === 'success') {
            props.setLoginProcess(true)
            const request_options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                })
            }
            let response = null;
            try{
                response = await fetch(`${API_URL}/api/v1/reset-password`, request_options)
            }catch(ex){
                setErrorMsg("Faild to fetch")
                props.setLoginProcess(false)
                return;
            }
            props.setLoginProcess(false)
            let res_code = response.status
            response = await response.json()
            // console.log("LOGIN : request_options ====>", res_code, response, response.role, response.access_token)
            if(!response.status){
                setErrorMsg(response.message)
                return;
            }
            // bad username/password
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                props.setLoginProcess(false)
                return;
            }
            setResetSuccess(true)
            props.setLoginProcess(false)
        }
    }

    const resetFormHandler = async(e) => {
        e.preventDefault()
        setErrorMsg("")
        let urlObj = new URLSearchParams(window.location.search)
        let reset_token = urlObj.get("token")

        if(!reset_token){
            setErrorMsg("Token invalid")
            return;
        }


        if(password.length >= 6) {
            props.setLoginProcess(true)
            const request_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+reset_token
                },
                body: JSON.stringify({
                    password: password
                })
            }
            let response = null;
            try{
                response = await fetch(`${API_URL}/api/v1/reset-current-password`, request_options)
            }catch(ex){
                setErrorMsg("Faild to fetch")
                props.setLoginProcess(false)
                return;
            }
            let res_code = response.status
            response = await response.json()
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                props.setLoginProcess(false)
                return;
            }
            setResetSuccess(true)
            props.setLoginProcess(false)
        }else{
            setErrorMsg("Password min length 6")
        }
    }

    const emailHandler = (e) => {
        setErrorMsg("")
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_green)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }   
        setEmail(e.target.value)
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    <img src={require("../../assets/images/logo-dark.png")} alt='linkvantage.net' style={{width:"50%"}}/>
                    <span>Reset Password</span>
                </div>
                <span style={{color:"red", textAlign:"center", fontSize:"large"}}>{errorMsg}</span>
                <div className={classes.form_container}>
                    {(passwordResetToeken)?
                    resetSuccess?
                    <div>
                        <h1 style={{textAlign: "center", fontSize: "revert",color: "gray"}}>Reset Success</h1>
                        <div style={{marginTop: "5em"}}>
                            <Link style={{float:"left", fontSize:"0.8em"}} to="/login" >Login here</Link>
                            <Link style={{float:"right", fontSize:"0.8em"}} to="/register" > Don't have account? Register here.</Link>
                        </div>
                    </div>
                    :
                    <form className={classes.form} onSubmit={resetFormHandler}>
                        <div className={classes.form_item}>
                            <label htmlFor='email'>Password</label>
                            <CustomInput1 image_src={(password.length>7)?lock_blue:lock_red} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={password.length>7}/>
                        </div>
                        <div>
                            <CustomButton style={{float:"right"}} name='Reset Now' color='blue' type='submit'/>
                        </div>
                    </form>
                    :
                    resetSuccess?
                        <div>
                            <h1 style={{textAlign: "center", fontSize: "revert",color: "gray"}}>Please check mail for followup</h1>
                            <div style={{marginTop: "5em"}}>
                                <Link style={{float:"left", fontSize:"0.8em"}} to="/login" >Login here</Link>
                                <Link style={{float:"right", fontSize:"0.8em"}} to="/register" > Don't have account? Register here.</Link>
                            </div>
                        </div>
                        :
                        <form className={classes.form} onSubmit={submitHandler}>
                            <div className={classes.form_item}>
                                <label htmlFor='email'>Email</label>
                                <CustomInput1 image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                            </div>
                            <div>
                                <Link style={{float:"left", fontSize:"0.8em"}} to="/login" >Login here</Link>
                                <Link style={{float:"right", fontSize:"0.8em"}} to="/register" > Don't have account? Register here.</Link>
                            </div>
                            <div>
                                <div className={register_classes.register_btn_div}>
                                    <CustomButton style={{float:"right"}} name='Reset Now' color='blue' type='submit'/>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword