import classes from './Register.module.scss'

import email_icon from '../../assets/icons/email.svg'
import eye_icon from '../../assets/icons/eye.svg'
// import eye_crossed_icon from '../../assets/icons/eye_crossed.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
// import email_green from '../../assets/icons/email_green.svg'
import lock from '../../assets/icons/lock.svg'
// import lock_red from '../../assets/icons/lock_red.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
// import lock_green from '../../assets/icons/lock_green.svg'

import CustomInput1 from '../Customs/CustomInput1/CustomInput1'
import CustomButton from '../Customs/CustomButton/CustomButton'

import { useState, useEffect } from 'react'
import { Link, redirect, useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
// import { userActions } from '../../store/user-slice'
// import { uiActions } from '../..//store/ui-slice'

import { TimeOutHandler} from '../../utils/helper_functions'

const Register = (props) => {
    
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const [nameStatus, setNameStatus] = useState('success')
    const [emailStatus, setEmailStatus] = useState('pending')
    const [passwordStatus, setPasswordStatus] = useState('pending')
    
    const [emailVerficationRequired, setEmailVerficationRequired] = useState(false)

    const [emailIcon, setEmailIcon] = useState(email_icon)
    const [passwordIcon, setPasswordIcon] = useState(lock)
    const [errorMsg, setErrorMsg] = useState("")

    const dispatch = useDispatch()
    const navigate = useNavigate ()

    useEffect(()=>{
        document.title = "LinkVantage | Register"
    }, [])

    if(props.adminLogin){
        return redirect("/login")
    }

    let access_token = localStorage.getItem('access_token')
    if(access_token){
        return navigate('/')
    }

    const API_URL = process.env.REACT_APP_API_URL

    const submitHandler = async (e) => {
        e.preventDefault()
        setEmailVerficationRequired(false)
        setErrorMsg("")
        if(password.length < 6){
            setErrorMsg("Password min length 6")
            return;
        }
        if(passwordStatus === 'success' && emailStatus === 'success' && nameStatus === 'success') {
            props.setLoginProcess(true)
            const request_options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: password,
                })
            }
            let response = null;
            try{
                response = await fetch(`${API_URL}/api/v1/register`, request_options)
            }catch(ex){
                setErrorMsg("Faild to fetch")
                props.setLoginProcess(false)
                return;
            }
            let res_code = response.status
            response = await response.json()
            // console.log("LOGIN : request_options ====>", res_code, response, response.role, response.access_token)

            // bad username/password
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                setErrorMsg(response.message)
                props.setLoginProcess(false)
                return;
            }

            if(res_code === 200 && ['ADMIN','MODERATOR', 'CUSTOMER'].includes(response.role)) {
                setEmailVerficationRequired(true)
                // localStorage.setItem('access_token', response.access_token)
                // localStorage.setItem('role', response.role)

                // dispatch(userActions.setUser(email))
                // dispatch(userActions.setToken(response.access_token))
                // dispatch(userActions.setIsAdmin(['ADMIN'].includes(response.role)))
                // dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(response.role)))
                
                // dispatch(uiActions.setIsLoggedIn(true))
                // dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR'].includes(response.role)))
                // // navigate('/', {replace: true })
            }
            TimeOutHandler(() => props.setLoginProcess(false))
        }
    }



    const emailHandler = (e) => {
        setErrorMsg("")
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_icon)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }
            
        setEmail(e.target.value)
    }

    const nameHandler  = (e) => {
        setErrorMsg("")
        let value = e.target.value

        if(value.length < 3) {
            setNameStatus('pending')
        }else{
            setNameStatus('success')
        }
        console.log(e.target.value, value.length)
        setName(e.target.value)
    }


    const passwordHandler = (e) => {
        setErrorMsg("")
        let value = e.target.value

        if(value.length === 0) {
            setPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setPasswordStatus('success')
            setPasswordIcon(lock_blue)
        }
        else { 
            setPasswordStatus('pending') 
            setPasswordIcon(lock_blue)
        }
        setPassword(value)
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    {/* <span className={classes.title}> {props.adminLogin ? "Admin Login":"Register"}</span> */}
                    <img src={require("../../assets/images/logo-dark.png")} alt='linkvantage.net' style={{width:"50%"}}/>
                    {/* <span>Sign Up</span> */}
                </div>
                <span style={{color:"red", textAlign:"center", fontSize:"large"}}>{errorMsg}</span>
                {
                    emailVerficationRequired?
                    <div>
                        <h1 style={{textAlign: "center", fontSize: "revert",color: "gray"}}>Please check mail for verification</h1>
                        <div style={{marginTop: "5em"}}>
                            <Link style={{float:"left", fontSize:"0.8em"}} to="/login" >Login here</Link>
                            {/* <Link style={{float:"right", fontSize:"0.8em"}} to="/register" > Don't have account? Register here.</Link> */}
                            <Link style={{float:"right", fontSize:"0.8em"}} to="/forgot-password" >Forgot password?</Link>
                        </div>
                    </div>
                    :
                    <div className={classes.form_container}>
                        <form className={classes.form} onSubmit={submitHandler}>
                            <div className={classes.form_item}>
                                <label htmlFor='name'>Name</label>
                                <CustomInput1 image_src={eye_icon} placeholder='Abracadabra' name='name' type='text' onChange={nameHandler} value={name} required status={nameStatus}/>
                            </div>
                            <div className={classes.form_item}>
                                <label htmlFor='email'>Email</label>
                                <CustomInput1 image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                            </div>
                            <div className={classes.form_item}>
                                <label htmlFor='password'>Password</label>
                                <CustomInput1 image_src={passwordIcon} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={passwordStatus}/>
                            </div>
                            <div>
                                <Link to="/login" style={{fontSize:"0.8em", float:"left"}} >Already have account? Login here.</Link>
                            </div>
                            <div className={classes.register_btn_div}>
                                <CustomButton name='Register Now' color='blue' type='submit'/>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    )
}

export default Register