import classes from './BillingCard.module.scss'
import Tooltip from '@mui/material/Tooltip'


const BillingCard = (props) => {
    return (
        <div className={classes.container}>
            <Tooltip title={props.tooltip} placement="top" arrow>
                <div className={classes.title}>{props.title}</div>
                <div className={classes.value}>{props.value}</div>
                <div className={classes.value} onClick={props.onClick}>
                    {props.image && <img className={classes.icon} src={props.image} alt="icon"/>}
                </div>
            </Tooltip>
        </div>
    )
}

export default BillingCard;