import classes from "./AdminTools.module.scss"
import report_classes from "../Admin/Reports.module.scss"

// packages
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector"

// components
import CustomButton from "../Customs/CustomButton/CustomButton"
import CustomSelect from '../Customs/CustomSelect/CustomSelect'

import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';

import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

// icons 
import edit from '../../assets/icons/pencil.svg'
import goback from '../../assets/icons/go_back.svg'
import { Link } from "react-router-dom";
import LoadingTable from "../LoadingTable/LoadingTable";

import axios from 'axios'




const AdminTools = () => {
    const tools_names = useSelector(state => state.ui.language.values.toolsContent)
    const btn_texts = useSelector(state=>state.ui.language.values.btn_texts)
    const API_URL = process.env.REACT_APP_API_URL

    // states
    const [showEditCodePopUp, setShowEditCodePopUp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [failLoading, setFailLoading] = useState(false)
    const [isLoadding, setIsLoadding] = useState(true)
    const [editCodeType, setEditCodeType] = useState('')
    const analyticsCodeAreaRef = useRef(null)

    const [analyticsCode, setAnalyticsCode] = useState("")

    // functions
    const handleToggleEdit = (e) => {
        setShowEditCodePopUp(!showEditCodePopUp)
        const target_id = e.target.id
        console.log("handle toggle edit clicked :", e.target.id)

        if (target_id === "1"){
            setEditCodeType(tools_names.before_head_tag)

        }else if(target_id === "2"){
            setEditCodeType(tools_names.before_body_tag)
        }

    }

    const handleSaveCode = () => {
        console.log("handle save code clicked")

    }

    const getAnalyticsCode = () => {
        setIsLoadding(true)
        // setTimeout(()=>setIsLoadding(false), 5000)
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/analytics-code`,
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };

        axios.request(config)
        .then((response) => {
            setAnalyticsCode(response.data.analytics_tracking_code)
            analyticsCodeAreaRef.current.innerHTML = response.data.analytics_tracking_code;
            setIsLoadding(false)
        })
        .catch((error) => {
            console.log("Ajax error", error)
            setIsLoadding(false)
            if(error && error.response && error.response.status > 400 ){
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    useEffect(()=>{
        getAnalyticsCode()
        document.title = "LinkVantage | Admin Tools";
    }, [])

    const onTextareaChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'analyticsCode') {
            setAnalyticsCode(value)
        }
    }

    const updateCode = ()=>{
        setIsLoadding(true)
        // setTimeout(()=>setIsLoadding(false), 5000)
        // console.log("headCode", headCode, bodyCode, code,"--->", headCodeAreaRef.current)
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/api/v1/analytics-code`,
            data: {
                "analytics_tracking_code": analyticsCode
            },
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };
        axios.request(config)
        .then((response) => {
            setIsLoadding(false)
            getAnalyticsCode()
        })
        .catch((error) => {
            console.log("Ajax error", error)
            setIsLoadding(false)
            if(error && error.response && error.response.status > 400 ){
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    const copyAnalyticsScript = () => {
        let scriptLink = `<script async type="text/javascript" src="${API_URL}/code/analytics"></script>`;
        navigator.clipboard.writeText(scriptLink)
    }

    return (
        <>
            {/* edit shows */}
            {showEditCodePopUp && 
            <PopUpCard  toggle={handleToggleEdit} header={tools_names.edit_code_title}>
                {loading && <CheckAnimation />}
                {failLoading && <FailAnimation />}

                <form className={classes.main_middle_edit_div}>
                    <div className={classes.middle_item_div}>
                        <div className={classes.middle_item_label_div}>
                            <label className={classes.middle_item_label}>
                                {editCodeType}
                            </label>
                            <img src={edit} alt='edit'/>
                        </div>
                        <div className={classes.middle_item_input_div}>
                            <textarea className={classes.middle_textarea} placeholder="" onChange={(e) => console.log(e)} required></textarea>
                        </div>
                    </div>

                    <div className={classes.edit_code_buttons}>
                        <div className={classes.customButton}>
                            <CustomButton color='green' name={btn_texts.save} onClick={handleSaveCode}/>
                        </div>
                    </div>
                </form>
            </PopUpCard>}


            {/* main components of tools page */}
            <div className={classes.container}>
                <div className={classes.main}>
                    <div className={classes.main_top}>
                        <div className={report_classes.main_title}>
                            <div className="heading">
                                Admin Analytics Code
                            </div>
                        </div>
                    </div> 

                    {isLoadding &&
                        <LoadingTable/>
                    }
                    <div className={classes.main_middle}>
                        <div className={classes.main_middle_top}>
                            <div className={classes.main_middle_title}>
                                Analytics Code
                            </div>
                            <div className={classes.main_middle_code_div}>
                                <div className={classes.main_middle_code}>
                                    <textarea ref={analyticsCodeAreaRef} className={classes.textarea_code} name={"analyticsCode"} onChange={onTextareaChange} defaultValue={analyticsCode}></textarea>
                                </div>

                                <div className={classes.main_middle_edit}>
                                    <CustomButton color='green' name="Copy Link" onClick={copyAnalyticsScript} />
                                    <CustomButton color='blue' name="Update" onClick={updateCode} />
                                </div>
                            </div>  
                        </div> 
                    </div> 
                </div> 
            </div> 
        </>
    )
}

export default AdminTools;