import classes from './PopUpCard.module.scss'

import cross from '../../../assets/icons/cross.svg'


import GrayWindow from '../GrayWindow/GrayWindow'
import { useSelector } from 'react-redux'

/**
 * toggle - a function responsible on closing the the component.
 * header - the text at the top of the component (Title).
 * 
 * @param {Function} toggle 
 * @param {string} header
 * 
 * @returns 
 */
const PopUpCard = (props) => {
    const current_language = useSelector(state => state.ui.language.code)

    return (
        <GrayWindow loading={props.loading} extraPadding={props.extraPadding} bigPopup={props.bigPopup} toggle={props.toggle}>
            <div className={classes.main}>
                <div className={classes.main_top} style={current_language === 'HEB' ? {justifyContent: 'center'} : {} }>
                   <span>{`${props.header}`}</span>
                    <img src={cross} alt='X' onClick={props.toggle}/>
                </div>
                <div className={classes.main_bottom}>
                    {props.children}
                </div>
            </div>
        </GrayWindow>
    )

}

export default PopUpCard;