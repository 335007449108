import classes from './LoginLoad.module.scss'
import foreshop from '../../assets/images/logo.png'

import LoadingTable from '../LoadingTable/LoadingTable'

const LoginLoad = () => {

    return (
        <div style={{background:"#e5e5e5", width:"100vw", height:"100vh", position:"absolute", zIndex:40}}>
            <div className={classes.container}>
                <div className={classes.main} style={{borderRadius: "1em"}}>
                    <div className={classes.top_container}>
                        <div className={classes.logo}>
                            <img src={require("../../assets/images/logo-dark.png")} alt='linkvantage.net' style={{width:"100%"}}/>
                            <p style={{textAlign:"center"}}>Please Wait ...</p>
                        </div>
                    </div>
                    <div className={classes.spinner}><div></div><div></div><div></div></div>
                    {/* <LoadingTable /> */}
                </div>
            </div>
        </div>
    )

}

export default LoginLoad;