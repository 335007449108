import classes from './TopBarMobile.module.scss'

// import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';
// import foreshop from '../../assets/images/logo.png'
// import logo_small from "../../assets/images/logo-small.png";
// import angle_up from '../../assets/icons/angle-up.svg'
// import logout from '../../assets/icons/logout.svg'
// import { userActions } from '../../store/user-slice';

import mobilenav_icon from '../../assets/icons/mobilenav.svg'
import moeny_bill from '../../assets/icons/money-bill-1-regular.svg'
import angle_down from '../../assets/icons/angle-down.svg'

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math'

import { uiActions } from '../../store/ui-slice'
import { useSelector } from 'react-redux';

import useWindowSize from '../../assets/hooks/useWindowSize.js'

const TopBarMobile = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { width } = useWindowSize()

    const userFullName = useSelector(state => state.user.username)
    const userBalance = useSelector(state => state.user.balance)
    const lastPaymentAmount = useSelector(state => state.user.last_payment_amount) 

    const [toggleOpen, setToggleOpen] = useState(false)

    const getTimeInGMT = (diffHours = 0) => {
        var currentTime = new Date()
        var utcTime = currentTime.getTime()
        var timeZoneOffset = diffHours * 60 * 60 * 1000;
        var gmtPlusTime = new Date(utcTime + timeZoneOffset).toISOString().slice(11, 19)
        return gmtPlusTime;
    }

    const [currentTime, setCurrentTime] = useState(getTimeInGMT(2))

    const logoutHandler = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        dispatch(uiActions.setIsLoggedIn(false))
        navigate('/login', { replace: true })
    }


    useEffect(()=>{
        window.timeUpdaterInterval = null;
        window.timeUpdaterInterval = setInterval(()=>{
            setCurrentTime(getTimeInGMT(2))
        }, 1000)
        
        document.addEventListener("click", (e)=>{
            if(e?.target?.id==="navbar-opener"){
                return;
            }
            setToggleOpen(false)
        })
    }, [])

    const showNavBarMobile = () => {
        props.setShowSideBar(!props.showSideBar)
    }

    // const numberWithCommas = (x) => {
    //     return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // }

    return (
        <div className={classes.container} style={{width:(props.isMobile)?"100vw":"100%"}}>

            <div className={classes.top}>
                <div className={classes.navbar} onClick={showNavBarMobile}>
                    {/* {width} */}
                    {width <= 767 && <img src={mobilenav_icon} alt='menu'/> }
                </div>

                <div className={classes.top_buttons}>
                    <div className={classes.button_container} style={{display:(props.isMobile)?"none":"block"}}>
                        <img src={moeny_bill} style={{width:"30px", marginTop:"4px", marginRight:"10px"}} alt="money"/>
                        <div style={{float:"right"}}>
                            {/* {userBalance} - {typeof userBalance} - {lastPaymentAmount} */}
                            <div className={classes.button_header} style={{marginTop:"0.5em"}}>Balance: $ {typeof userBalance === 'number'? round(userBalance, 2).toLocaleString() : 0.00}</div>
                            {/* <div className={classes.button_text}>Last payment amount: $ {typeof lastPaymentAmount === 'number'? round(lastPaymentAmount, 2).toLocaleString() : 0.00}</div> */}
                        </div>
                    </div>
                    <div className={classes.button_container} style={{display:(props.isMobile)?"none":"block"}}>
                        <div className={classes.button_header}>GMT +02:00</div>
                        <div className={classes.button_text}>{currentTime}</div>
                    </div>
                    <div className={classes.button_container} style={{display:"contents"}}>
                        <div style={{marginRight:"1em"}}>
                            <div className={classes.button_header}>{userFullName}</div>
                            <div className={classes.button_text}>Profile</div>
                        </div>
                        <img id="navbar-opener" src={angle_down} onClick={()=>{setToggleOpen(!toggleOpen)}} style={{transition: "transform 500ms ease 0s", transform: toggleOpen?"rotate(180deg)":"rotate(0deg)", width:"15px", marginTop:"4px", marginRight:"10px"}} alt="angle-down"/>
                        {
                            toggleOpen
                            &&
                            <div className='profile-dropdown-container'>
                                <ul>
                                    <li onClick={()=>{
                                        navigate('/profile',{ replace: true })
                                        setToggleOpen(false)
                                    }}>Profile</li>
                                    <li onClick={logoutHandler}>Logout</li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TopBarMobile