import classes from './billing.module.scss'
import user_report_classes from '../Reports/Reports.module.scss'
import report_classes from '../Admin/Reports.module.scss'

import export_icon from '../../assets/icons/export.svg'
import invalid_revenue_icon from '../../assets/icons/information_white.svg'
import search_gray_icon from '../../assets/icons/search.svg'
import go_back_icon from '../../assets/icons/go_back.svg'

// import payment_requests_icon from '../../assets/icons/eye.svg'
// import reviewed_requests_icon from '../../assets/icons/reviewed.svg'
// import approved_requests_icon from '../../assets/icons/approved_pr.svg'
// import not_sent_requests_icon from '../../assets/icons/not_sent.svg'

// react packages
import { useState, useEffect } from 'react'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import ReportHeaders from './ReportHeaders'

import htmlparser from 'html-react-parser'

// 3rd party packages
import axios from 'axios'
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math'
import { Col } from 'rsuite'
import { ImpulseSpinner } from "react-spinners-kit"  
import { ToastContainer, toast } from 'react-toastify'


import { DateRangePicker } from 'rsuite'
import { BsCalendar2MonthFill } from 'react-icons/bs';

import { useCookies } from 'react-cookie'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'



// components
import LoadingTable from '../LoadingTable/LoadingTable'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import BillingCard from "../Cards/BillingCard"

// helpers function
import { renderMonthsSelectDiv, parseDateStringToFormat } from '../../utils/helper_functions'
import { get } from 'sortablejs'
import moment from 'moment'
import { useCallback } from 'react'



const AdminBilling = () => {
    // env variables
    const API_URL = process.env.REACT_APP_API_URL

    // headers
    const pending_pr_table_headers = ["#", "User", "Email", "Current Balance", "Request Months", "Total Amount", "Requested On", "Method", "Action"]
    const processed_pr_table_headers = ["#", "User", "Email", "Current Balance", "Request Months", "Total Amount", "Requested On", "Method", "Status", "Action", "Tracking ID", "Remark",] 
    // ad revenue affiliates, ad revenue lv, total (GAM-2%), total GAM
    const revenue_table_headers = ["#", "Offer Name", "ad revenue aff", "ad revenue lv", "total rev", "total gam", "total ga4", "conversion count", "conversion revenue"]
    const breakdown_revenue_table_headers = ["#", "User", "Email", "Balance", "Ref Code",  "ga4_rev",  "adm_rev", "adm_rev_after_default", "adm_rev_after_all"]
    const breakdown_pr = ['#', 'Month', 'Total Revenue']
    const invalid_traffic_table_headers = ['#', 'Month', 'Site', 'Invalid Traffic']
    
    const export_pr_table_headers = ["#", "User Name", "Payment Email", "Payment Months", "Requested Amount", "Payment Requested On", "Payment Method", "Bank Name", "Bank Address", "Account Number", "Swift Code", "Company Name", "Business ID", "Number", "Address", "Phone", "City", "Zip Code", "Province", "Country"]
    

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)
    const [fragmentLoading, setFragmentLoading] = useState(false)
    const [popupLoading, setPopupLoading] = useState(false)
    const [breakdownReportLoading, setBreakdownReportLoading] = useState(false)

    // toast alert states
    const [isSuccess, setIsSuccess] = useState(false)                          
    const [isError, setIsError] = useState(false)

    // fragment section
    const [defaultFragment, setDefaultFragment] = useState('payment_request')

    // popup section
    const [showPrApprovePopup, setShowPrApprovePopup] = useState(false)
    const [showBreakdownReportPopup, setShowBreakdownReportPopup] = useState(false)
    const [targetDataItem, settargetDataItem] = useState({})

    // invalid traffic popup
    const [invalidTrafficLoading, setInvalidTrafficLoading] = useState(false)
    const [showInvalidTrafficPopup, setShowInvalidTrafficPopup] = useState(false)
    const [trackingDomains, setTrackingDomains] = useState([])
    const [invalidTrafficData, setInvalidTrafficData] = useState([])
    const [invalidTrafficDataBackup, setInvalidTrafficDataBackup] = useState([])
    const [invalidTrafficSearchValue, setInvalidTrafficSearchValue] = useState('')

    const [addInvalidRule, setAddInvalidRule] = useState(false)
    const [invalidMonth, setInvalidMonth] = useState('')
    const [invalidSite, setInvalidSite] = useState('all')
    const [invalidTraffic, setInvalidTraffic] = useState('')


    // search states
    const [prSearchValue, setPrSearchValue] = useState('')
    const [processedSearchValue, setProcessedSearchValue] = useState('')
    const [billingReportSearch, setBillingReportSearch] = useState('')

    // select month states
    const [months, setMonths] = useState([])
    const [selectedMonth, setSelectedMonth] = useState('all')
    const [getPrArRevs, setGetPrArRevs] = useState(false)
    // const [breakdownSelectedMonth, setBreakdownSelectedMonth] = useState('')

    const [visitedPage, setVisitedPage] = useState([])



    // tracking id state
    const [trackingID, setTrackingId] = useState('')
    const [remark, setRemark] = useState('')

    // Data states 
    const [PaymentRequestData, setPaymentRequestData] = useState([])
    const [PaymentRequestDataBackup, setPaymentRequestDataBackup] = useState([])
    const [PaymentRequestSummary, setPaymentRequestSummary] = useState({
        'requested':0,
        'reviewed':0,
        'approved':0,
        'not_sent':0,

        'requested_amount':0,
        'reviewed_amount':0,
        'approved_amount':0,
        'not_sent_amount':0
    })
    const [ApprovedRequestData, setApprovedRequestData] = useState([])
    const [ApprovedRequestDataBackup, setApprovedRequestDataBackup] = useState([])
    const [ApprovedRequestSummary, setApprovedRequestSummary] = useState({
        'paid':0,
        'denied':0,

        'paid_amount':0,
        'denied_amount':0
    })


    const [RevenueReport, setRevenueReport] = useState([])
    const [RevenueReportBackup, setRevenueReportBackup] = useState([])
    const [RevenueReportSummary, setRevenueReportSummary] = useState({
        'ad_revenue':0,
        'cpa_revenue':0,
        'ga4_revenue':0,
        'google_ad_manager':0,

        'ad_revenue_amount':0,
        'cpa_revenue_amount':0,
        'ga4_revenue_amount':0,
        'google_ad_manager_amount':0
    })
    

    const [BreakdownRevenueReport, setBreakdownRevenueReport] = useState([])
    const [BreakdownRevenueReportBackup, setBreakdownRevenueReportBackup] = useState([])
    const [breakdownSearchValue, setBreakdownSearchValue] = useState('')
    
    const [cookies, setCookie] = useCookies(['revenue_dates'])

    const [selectionRange, setSelectionRange] = useState([
        cookies.revenue_dates?new Date(cookies.revenue_dates[0]):new Date("January 1, 2023"),
        cookies.revenue_dates?new Date(cookies.revenue_dates[1]):new Date()
    ])


    // ################################## useEffect section ##################################
    // ################################## useEffect section ##################################
    const getPaymentRequests = async (dateRange=false) => {
        // console.log('getting rev report :', '\n\n', selectionRange[0].getFullYear(), selectionRange[0].getMonth(), '\n\n', selectionRange[1].toLocaleString())

        if (!selectionRange[0] || !selectionRange[1]){  
            toast.error('Please select a valid date range')
            setIsError(true)
            return
        }
        let sDate = parseDateStringToFormat(dateRange?dateRange[0]:selectionRange[0]);
        let eDate = parseDateStringToFormat(dateRange?dateRange[1]:selectionRange[1]);
        
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/admin-billing?start_date=${sDate}&end_date=${eDate}`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token }
        }
        
        axios.request(config)
        .then((response) => {
            console.log('\nadmin billing res data :', response.data)

            setPaymentRequestSummary(response.data.pr_summary)  
            setPaymentRequestData(response.data.pr_objs)
            setPaymentRequestDataBackup(response.data.pr_objs)

            setApprovedRequestSummary(response.data.ar_summary)  
            setApprovedRequestData(response.data.ar_objs)
            setApprovedRequestDataBackup(response.data.ar_objs)
            
            setRevenueReport(response.data.rev_objs)
            setRevenueReportBackup(response.data.rev_objs)
            setRevenueReportSummary(response.data.rev_summary)  

            setMonths(response.data.months)
            setLoadingResults(false)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
            setLoadingResults(false)
        })
    }
    useEffect(() => {
        getPaymentRequests()
    },[])



    // ################################## Search section ##################################
    // ################################## Search section ##################################
    // ################################## Search section ##################################
    useEffect(() => {
        if (prSearchValue !== ''){
            var searchQuery = prSearchValue.toLowerCase()
            var filteredData = PaymentRequestDataBackup.filter(item =>
                item.name.toLowerCase().includes(searchQuery) ||
                item.email.toLowerCase().includes(searchQuery) ||
                item.month.toLowerCase().includes(searchQuery) ||
                item.payment_method.toLowerCase().includes(searchQuery) ||
                item.payment_requested_on.toLowerCase().includes(searchQuery) ||
                round(item.total_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.balance, 2).toString().toLowerCase().includes(searchQuery)
            )
            setPaymentRequestData(filteredData)
        }else{
            setPaymentRequestData(PaymentRequestDataBackup)
        }
    },[prSearchValue])
    

    useEffect(() => {
        if (processedSearchValue !== ''){
            var searchQuery = processedSearchValue.toLowerCase()
            var filteredData = ApprovedRequestDataBackup.filter(item =>
                item.name.toLowerCase().includes(searchQuery) ||
                item.email.toLowerCase().includes(searchQuery) ||
                item.month.toLowerCase().includes(searchQuery) ||
                item.payment_method.toLowerCase().includes(searchQuery) ||
                item.payment_status.toLowerCase().includes(searchQuery) ||
                item.payment_requested_on.toLowerCase().includes(searchQuery) ||
                round(item.total_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.balance, 2).toString().toLowerCase().includes(searchQuery) ||
                item._id.includes(searchQuery) ||
                item.tracking_id.includes(searchQuery)
            )
            setApprovedRequestData(filteredData)
        }else{
            setApprovedRequestData(ApprovedRequestDataBackup)
        }
    },[processedSearchValue])


    useEffect(() => {
        if (billingReportSearch !== ''){
            var searchQuery = billingReportSearch.toLowerCase()
            var filteredData = RevenueReportBackup.filter(item =>
                item.offer_name.toLowerCase().includes(searchQuery) ||
                round(item.ad_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.cpa_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.ga4_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.google_ad_manager_revenue, 2).toString().toLowerCase().includes(searchQuery)
            )
            setRevenueReport(filteredData)
        }else{
            setRevenueReport(RevenueReportBackup)
        }
    },[billingReportSearch])

                
    useEffect(() => {
        if (breakdownSearchValue !== ''){
            var searchQuery = breakdownSearchValue.toLowerCase()
            var filteredData = BreakdownRevenueReportBackup.filter(item =>

                item.name.toLowerCase().includes(searchQuery) ||
                item.email.toLowerCase().includes(searchQuery) ||
                item.ref_code.toLowerCase().includes(searchQuery) ||
                round(item.balance, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.ad_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.cpa_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.ga4_revenue, 2).toString().toLowerCase().includes(searchQuery) ||
                round(item.google_ad_manager_revenue, 2).toString().toLowerCase().includes(searchQuery)
            )
            setBreakdownRevenueReport(filteredData)
        }else{
            setBreakdownRevenueReport(BreakdownRevenueReportBackup)
        }
    },[breakdownSearchValue])













    // ################################## Select Month Section ##################################
    // ################################## Select Month Section ##################################
    // ################################## Select Month Section ##################################
    const getOnlyPrArRev = async (api_endpoint) => {
        let url = `${API_URL}/api/v1/prs-ars-revs?month=${selectedMonth}&api=${api_endpoint}`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: url,
            headers: {
              'Authorization': 'Bearer '+token
            }
        }
        
        axios.request(config)
        .then((response) => {
            if(defaultFragment==="payment_request"){  
                setPaymentRequestData(response.data.res_objs)
                setPaymentRequestDataBackup(response.data.res_objs)

            }else if(defaultFragment==="processed_request"){
                setApprovedRequestData(response.data.res_objs)
                setApprovedRequestDataBackup(response.data.res_objs)

            }else if(defaultFragment==="revenue"){
                setRevenueReport(response.data.res_objs)
                setRevenueReportBackup(response.data.res_objs)
            }
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
        }).finally(()=>{
            setFragmentLoading(false)
        })
    }


    useEffect(() => {
        setTrackingId('')
        setRemark('')

        if (selectedMonth !== '' && getPrArRevs){
            // console.log("fragment loading :")

            setFragmentLoading(true)
            setVisitedPage([defaultFragment])

            if(defaultFragment==="payment_request"){getOnlyPrArRev('prs')}
            else if(defaultFragment==="processed_request"){getOnlyPrArRev('ars')}
            else if(defaultFragment==="revenue"){getOnlyPrArRev('revs')}
        }
    },[selectedMonth])


    useEffect(() => {
        if(getPrArRevs && !visitedPage.includes(defaultFragment)){
            setFragmentLoading(true)
            setVisitedPage([...visitedPage, defaultFragment])

            if(defaultFragment==="payment_request"){getOnlyPrArRev('prs')}
            else if(defaultFragment==="processed_request"){getOnlyPrArRev('ars')}
            else if(defaultFragment==="revenue"){getOnlyPrArRev('revs')}
        }
    },[defaultFragment])


    const reloadData =  useCallback(() => {
        console.log("called now")
        getBreakdownRevenueReport()
    }, [])



    useEffect(()=>{
        console.log("changed")
        reloadData()
    }, [reloadData, selectionRange]);

    const getBreakdownRevenueReport = async () => {
        setBreakdownReportLoading(true)
        // console.log('getting breakdown rev report :', selectionRange[0].getFullYear(), selectionRange[0].getMonth(), '\n\n', selectionRange[1].toLocaleString())

        if (!selectionRange[0] || !selectionRange[1]){  
            toast.error('Please select a valid date range')
            setIsError(true)
            return
        }
        console.log("========> loading <-----")
        console.log(selectionRange)

        let start_date = moment(selectionRange[0]).format('YYYY-MM-DD')
        let end_date =  moment(selectionRange[1]).format('YYYY-MM-DD')
        

        let url = `${API_URL}/api/v1/breakdown-revenue?start_date=${start_date}&end_date=${end_date}`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: {
                "offer_id":targetDataItem.offer_id,
                // "category":targetDataItem.category,
                // "payout":targetDataItem.payout,
                // "ad_revenue":targetDataItem.ad_revenue,
                // "google_ad_manager_revenue":targetDataItem.google_ad_manager_revenue,
            }
        }
        axios.request(config)
        .then((response) => {
            console.log(response.data.user_objs)
            setBreakdownRevenueReport(response.data.user_objs)   
            setBreakdownRevenueReportBackup(response.data.user_objs)   

            toast.success(response.data.message || "Success")
            setIsSuccess(true)
            setBreakdownReportLoading(false)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
            setBreakdownReportLoading(false)
        })
    }

    useEffect(() => {
        if(showBreakdownReportPopup && targetDataItem){
            getBreakdownRevenueReport()
        }
    },[showBreakdownReportPopup])


    



    // const submitHandler = async(e) => {
    //     e.preventDefault()
        // if(identifierStatus === 'success') {
        //     setLoading(false)
        //     let access_token = localStorage.getItem('access_token')
    
        //     const request_options = {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer '+access_token
        //         },
        //         body: JSON.stringify({
        //             identifier: identifier
        //         })
        //     }
        //     let response = await fetch(`${API_URL}/api/v1/create_link`, request_options)
        //     let res_code = response.status
        //     response = await response.json()
        //     // console.log("LOGIN : request_options ====>", res_code, response, response.role, response.access_token)

        //     // bad username/password
        //     if(res_code >= 400) {
        //         setIdentifierStatus('error')
        //         setLoading(false)
        //         return
        //     }

        //     if(res_code === 200 && response.status === 1) {
        //         setToggleAddLinkPopUp(false)
        //         getAndLoadMyLinks()
        //     }
        // }
    // }


    // ################################## handler functions section ##################################
    const close_popup_default_tracking_id_remark = () =>{
        setShowPrApprovePopup(false) 
        setTrackingId('')
        setRemark('')
    }

    const changePrStatus = (target_pr_obj, target_pr_status) => { 
        console.log("Approving PR... :", target_pr_obj, target_pr_status, trackingID, remark)

        if ((target_pr_status === 'paid' && trackingID ==='') || (target_pr_status === 'denied' && remark === '')){
            setIsError(true)
            if(target_pr_status === 'paid'){
                toast.error('Tracking ID is required when payment request is Paid')
            }else{
                toast.error('Remark is required when payment request is Denied')
            }
            return
        }

        setPopupLoading(true)
        let url = `${API_URL}/api/v1/change-pr-status`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: {
                "default_month":selectedMonth,
                
                "pr_ids": target_pr_obj.pr_ids,
                "user_id": target_pr_obj.user_id,

                "month": target_pr_obj.month,
                "total_revenue": target_pr_obj.total_revenue,
                "breakdown_res": target_pr_obj.breakdown_res,
                
                "pr_status": target_pr_status,
                'tracking_id':trackingID,
                'remark':remark,
            }
        }
        axios.request(config)
        .then((response) => {
            setPaymentRequestSummary(response.data.pr_summary)   
            setPaymentRequestData(response.data.pr_objs)   
            setPaymentRequestDataBackup(response.data.pr_objs)   

            setApprovedRequestSummary(response.data.ar_summary)
            setApprovedRequestData(response.data.ar_objs)   
            setApprovedRequestDataBackup(response.data.ar_objs)

            toast.success(response.data.message || "Success")
            setIsSuccess(true)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
        }).finally(()=>{
            setPopupLoading(false)
            close_popup_default_tracking_id_remark()
        })
    }

    //  ################################## Render tables section ##################################
    //  ################################## Render tables section ##################################
    const renderPaymentRequests = () =>{
        let prData = []
        PaymentRequestData.forEach((data, index) =>  {
            prData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>$ {round(data.balance, 2).toLocaleString()}</td>
                    <td>{htmlparser(data.month)}</td>
                    <td>$ {round(data.total_revenue, 2).toLocaleString()}</td>
                    <td>{htmlparser(data.payment_requested_on)}</td>
                    <td>
                        {data.payment_method.toUpperCase() === 'PAYPAL' && <div className={classes.paypal}>{data.payment_method}</div>}
                        {data.payment_method.toUpperCase() === 'PAYONEER' && <div className={classes.payoneer}>{data.payment_method}</div>}
                        {data.payment_method.toUpperCase() === 'BANK' && <div className={classes.bank}>{data.payment_method}</div>}
                    </td>
                    <td className={classes.btn_div}>                                
                        <button className={classes.default_green_btn} onClick={() => {setShowPrApprovePopup(true); settargetDataItem(data); setTrackingId(data.tracking_id); setRemark(data.remark)}}>Review</button>
                    </td>
                </tr>
            )
        })

        return prData
    }
    

    const renderProcessedRequests = () =>{
        let processedData = []
        ApprovedRequestData.forEach((data, index) =>  {
            processedData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>$ {round(data.balance, 2).toLocaleString()}</td>
                    <td>{htmlparser(data.month)}</td>
                    <td>$ {round(data.total_revenue, 2).toLocaleString()}</td>
                    <td>{htmlparser(data.payment_requested_on)}</td>
                    <td>
                        {data.payment_method.toUpperCase() === 'PAYPAL' && <div className={classes.paypal}>{data.payment_method}</div>}
                        {data.payment_method.toUpperCase() === 'PAYONEER' && <div className={classes.payoneer}>{data.payment_method}</div>}
                        {data.payment_method.toUpperCase() === 'BANK' && <div className={classes.bank}>{data.payment_method}</div>}
                    </td>
                    <td>
                        {data.payment_status === 'PAID' && <div className={classes.green_text}>{data.payment_status}</div>}
                        {data.payment_status === 'DENIED' && <div className={classes.red_text}>{data.payment_status}</div>}
                    </td>
                    <td><button className={classes.default_green_btn} onClick={() => {setShowPrApprovePopup(true); settargetDataItem(data); setTrackingId(data.tracking_id); setRemark(data.remark)}}>Review</button></td>
                    <td>{data.tracking_id}</td>
                    <td>{data.remark}</td>
                </tr>
            )
        })
        return processedData
    }

    const renderRevenueReports = () => {
        // console.log('\n\nrev reports ', RevenueReport.length)
        let abData = []
        RevenueReport.forEach((data, index) =>  {
            abData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td className={report_classes.cursor_pointer} onClick={()=> {settargetDataItem(data); setShowBreakdownReportPopup(true); setBreakdownReportLoading(true)}}>{data.offer_name}</td>
                    <td>$ {round(data.ad_revenue_aff, 2).toLocaleString()}</td>
                    <td>$ {round(data.ad_revenue_lv, 2).toLocaleString()}</td>
                    <td>$ {round(data.adm_revenue_deduct, 2).toLocaleString()}</td>
                    <td >$ {round(data.total_gam, 2).toLocaleString()}</td>
                    <td>$ {round(data.ga4_revenue, 2).toLocaleString()}</td>
                    <td> {round(data.conversion_count, 2).toLocaleString()}</td>
                    <td>$ {round(data.conversion_revenue, 2).toLocaleString()}</td>
                </tr>
            )
        })
        return abData
    }

    const renderBreakdownRevenueReports = () => {
        let abData = []
        // ["#", "User", "Email", "Balance", "Ref Code",  "ga4_rev",  "ga4_after_default",  "ga4_after_all", "adm_rev", "adm_rev_after_default", "adm_rev_after_all"]
        BreakdownRevenueReport.forEach((data, index) =>  {
            abData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>$ {round(data.balance, 2).toLocaleString()}</td>
                    <td>{data.identifier}</td>

                    <td>$ {round(data.ga4_rev, 2).toLocaleString()}</td>
                    {/* <td>$ {round(data.ga4_after_default, 2).toLocaleString()} ({data.default_deduction}%) </td>
                    <td>$ {round(data.ga4_after_all, 2).toLocaleString()} ({data.payoutPer}%)</td> */}

                    <td>$ {round(data.adm_rev, 2).toLocaleString()}</td>
                    <td>$ {round(data.adm_rev_after_default, 2).toLocaleString()} ({data.default_deduction}%)</td>
                    <td>$ {round(data.adm_rev_after_all, 2).toLocaleString()} ({data.payoutPer}%)</td>

                </tr>
            )
        })
        return abData
    }


    const renderBreakdownPR = (breakdown_res) => {
        let breakdownPrData = []
        breakdown_res.forEach((data, index) =>  {
            breakdownPrData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.month}</td>
                    <td>$ {round(data.total_revenue, 2).toLocaleString()}</td>
                </tr>
            )
        })
        return breakdownPrData
    }


    const renderInvalidTraffic = () => {
        let tempInvalidTrafficData = []
        invalidTrafficData.forEach((data, index) =>  {
            tempInvalidTrafficData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{data.month}</td>
                    <td>{data.site === 'all'? "All" : data.site}</td>
                    <td>{data.invalid_traffic_value} %</td>
                </tr>
            )
        })
        return tempInvalidTrafficData
    }



    const handleExport = () => {
        // console.log("Handle export clicked :", PaymentRequestData, PaymentRequestData.length)
        if(PaymentRequestData.length === 0){
            toast.error('No data to export')
            setIsError(true)
            return
        }

        var CsvString = "";
        var export_headers_len = export_pr_table_headers.length - 1
        console.log('export headers length :', export_headers_len, typeof export_headers_len)

        export_pr_table_headers.forEach((each_header, each_index) => {
            if(export_headers_len === each_index){
                CsvString += each_header
            }else{
                CsvString += each_header + ','
            }
        })
        CsvString += "\n";

        PaymentRequestData.forEach(function(RowItem, RowIndex) {
            CsvString += (RowIndex+1) + ','
            CsvString += '"' + RowItem.payment_username + '",'
            CsvString += '"' + RowItem.payment_email + '",'
            CsvString += '"' + RowItem.month_str.replaceAll(',', ' | ') + '",'
            CsvString += '$ '+ round(RowItem.total_revenue, 2) + ','
            CsvString += '"' + RowItem.payment_requested_on.replace('<br>', ' ') + '",'
            CsvString += '"' + RowItem.payment_method + '",'

            if(RowItem.payment_method === 'BANK'){
                CsvString += '"' + RowItem.bank_name + '",'
                CsvString += '"' + RowItem.bank_address + '",'
                CsvString += '"' + RowItem.account_number + '",'
                CsvString += '"' + RowItem.swift_code + '",'

                CsvString += '"' + RowItem.company_name + '",'
                CsvString += '"' + RowItem.business_id + '",'
                CsvString += '"' + RowItem.number + '",'
                CsvString += '"' + RowItem.address + '",'

                CsvString += '"' + RowItem.phone + '",'
                CsvString += '"' + RowItem.city + '",'
                CsvString += '"' + RowItem.zip_code + '",'
                CsvString += '"' + RowItem.province + '",'
                CsvString += '"' + RowItem.country
            }
            CsvString += "\n";
        })
    
        // initiate the download process
        CsvString = "data:application/csv," + encodeURIComponent(CsvString);
        var x = document.createElement("A");
        x.setAttribute("href", CsvString );
        x.setAttribute("download", "export_prs.csv");
        document.body.appendChild(x);
        x.click();
    }




    // invalid traffic setup functionality
    const handleAddInvalidRule = () => {
        console.log('adding invalid rule :', invalidMonth, invalidSite, invalidTraffic)

        if(invalidMonth === '' || invalidSite === '' || invalidTraffic === ''){
            setIsError(true)
            toast.error('All fields are required')
            return
        }

        setInvalidTrafficLoading(true)
        let url = `${API_URL}/api/v1/add-invalid-traffic`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token },
            data: {
                "month": invalidMonth,
                "invalid_traffic_value": invalidTraffic,
                "site": invalidSite,
            }
        }
        axios.request(config)
        .then((response) => {
            toast.success(response.data.message || "Success")
            setIsSuccess(true)
            
            setInvalidMonth('')
            setInvalidTraffic('')
            setInvalidSite('all')
            setAddInvalidRule(false)

            getInvalidTraffic()
            setInvalidTrafficLoading(false)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.error || "Error Occurred")
                toast.error(error.response.data.error || "Error Occurred")
                setIsError(true)
                setInvalidTrafficLoading(false)
            }
        })
    }

    const getInvalidTraffic = () => {
        setInvalidTrafficLoading(true)

        let url = `${API_URL}/api/v1/invalid-traffic`
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+token }

        }
        axios.request(config)
        .then((response) => {
            toast.success(response.data.message || "Success")
            setIsSuccess(true)

            setInvalidTrafficData(response.data.invalid_traffic_objs)
            setInvalidTrafficDataBackup(response.data.invalid_traffic_objs)
            setTrackingDomains(response.data.tracking_domains)

            setInvalidTrafficLoading(false)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
                setInvalidTrafficLoading(false)
            }
        })

    }

    const handleInvalidRevenue = () => {
        setShowInvalidTrafficPopup(true)

    }

    useEffect(() => {
        if (invalidTrafficSearchValue !== ''){
            var searchQuery = invalidTrafficSearchValue.toLowerCase()
            var filteredData = invalidTrafficDataBackup.filter(item =>
                item.month.toLowerCase().includes(searchQuery) ||
                item.site.toLowerCase().includes(searchQuery) ||
                round(item.invalid_traffic_value, 2).toString().toLowerCase().includes(searchQuery)
            )
            setInvalidTrafficData(filteredData)
        }else{
            setInvalidTrafficData(invalidTrafficDataBackup)
        }
    },[invalidTrafficSearchValue])

    useEffect(() => {   
        if(showInvalidTrafficPopup){
            getInvalidTraffic()
        }
    }, [showInvalidTrafficPopup])



    // daterange picker select for revenue & breakdown report
    const handleSelect = (dates) => {
        console.log("selected: ", dates)
        // if(dates){
        //     setCookie('revenue_dates', dates)
        //     setSelectionRange(dates)
        // }else{
        //     var full_dates = [new Date("January 1, 2023"), new Date()]
        //     setCookie('revenue_dates', full_dates)
        //     setSelectionRange(full_dates)
        // }
        setCookie('revenue_dates', dates)
        setSelectionRange(dates)
    
        getPaymentRequests(dates)
        // getBreakdownRevenueReport()
    }



    // ################################## AdminBilling Component Section ##################################
    // ################################## AdminBilling Component Section ##################################
    return (
        <div className={classes.container}>
            <div className={classes.main}>
                {/* toast component for entire page */}
                {(isSuccess || isError) && <ToastContainer/>}

                {/* popup card section */}
                {(showPrApprovePopup) && (
                <PopUpCard toggle={close_popup_default_tracking_id_remark} header='Review Payment Request'>
                    <div className={classes.popup_content_div}>
                        {showPrApprovePopup && 
                            <div className={classes.popup_content_div_top}>
                                <div className={classes.popup_content_main}>Follow the <b>Step-By-Step Guidelines</b> and change the <b>Payment Request Status :</b></div>
                                    <div className={classes.form_info_div_each}>
                                        <label>Step 1 :</label>
                                        <div className={classes.all_info_div}>
                                            <div className={`${classes.all_info_div_each} ${classes.value}`}>Deposit/Send funds to following user</div>
                                            <div className={classes.all_info_div_each}>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>User </div>
                                                    <div className={classes.value}>{targetDataItem.payment_username}</div>
                                                </div>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>Email </div>
                                                    <div className={classes.value}>{targetDataItem.payment_email}</div>
                                                </div>
                                            </div>
                                            <div className={classes.all_info_div_each}>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>Method </div>
                                                    {targetDataItem.payment_method === 'PAYPAL' && <div className={classes.paypal}>{targetDataItem.payment_method}</div>}
                                                    {targetDataItem.payment_method === 'PAYONEER' && <div className={classes.payoneer}>{targetDataItem.payment_method}</div>}
                                                    {targetDataItem.payment_method === 'BANK' && <div className={classes.bank}>{targetDataItem.payment_method}</div>}
                                                </div>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>Total </div>
                                                    <div className={classes.value}>${round(targetDataItem.total_revenue, 2).toLocaleString()}</div>
                                                </div>
                                            </div>
                                            <div className={classes.all_info_div_each}>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>Months </div>
                                                    <div className={classes.value}>{targetDataItem.month.replace('<br>', '')}</div>
                                                </div>
                                                <div className={classes.all_info_div_each_50}>
                                                    <div className={classes.label}>Status </div>
                                                    <div className={classes.value}>
                                                        {targetDataItem.payment_status === ''? (
                                                            <div className={classes.blue_text}>Requested</div>
                                                        ):(
                                                            <>
                                                                {targetDataItem.payment_status === 'PAID' && <div className={classes.green_text}>{targetDataItem.payment_status}</div>}
                                                                {targetDataItem.payment_status === 'DENIED' && <div className={classes.red_text}>{targetDataItem.payment_status}</div>}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>                                            
                                            <div className={classes.popup_content_table_div}>
                                                <ResponsiveTable>
                                                    <thead><ReportHeaders header_names={breakdown_pr}/></thead>
                                                    {(targetDataItem.breakdown_res.length===0)?(
                                                        <span className={classes.noData}>No monthly revenue data</span>
                                                    ):(
                                                        <tbody className={user_report_classes.table_data}>{renderBreakdownPR(targetDataItem.breakdown_res)}</tbody>
                                                    )}
                                                </ResponsiveTable>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={classes.form_info_div_each}>
                                        <label>Step 2 :</label>
                                        <div>Check transaction status in payment gateway and add tracking ID</div>
                                    </div>

                                    <div className={classes.form_div_no_padding}>
                                        <div className={classes.form_child_div}>
                                            <div>
                                                <label>Tracking ID</label>
                                                <input name="tracking_id" placeholder="Tracking ID" onChange={(e)=>setTrackingId(e.target.value)} value={trackingID}/>
                                            </div>
                                            <div>
                                                <label>Notes</label>
                                                <input name="remark" placeholder="Remark/Notes" onChange={(e)=>setRemark(e.target.value)} value={remark}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.form_info_div_each}>
                                        <label>Step 3 :</label>
                                        <div>Finally, change the <b>status</b> accordingly</div>
                                    </div>

                                    <div className={classes.form_info_div_each}>
                                        <div>If above request is <b>Ineligible For Payment,</b> click <b>Deny</b> to refuse.</div>
                                    </div>
                            </div>
                        }

                        <div className={report_classes.bottom_buttons}>
                            {popupLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                            <button onClick={close_popup_default_tracking_id_remark} className={report_classes.cancel_btn}>Cancel</button>

                            {showPrApprovePopup && (
                            <>
                                <button onClick={()=>{changePrStatus(targetDataItem, 'denied')}} className={classes.default_red_btn}>Deny</button>
                                <button onClick={()=>{changePrStatus(targetDataItem, 'paid')}} className={classes.default_green_btn}>Paid</button>
                            </>)}
                        </div>
                    </div>
                </PopUpCard>
                )}

                


                {/* offer breakdown revenue report for each affiliate user */}
                {showInvalidTrafficPopup && (
                    <PopUpCard toggle={()=>{setShowInvalidTrafficPopup(false); setAddInvalidRule(false); setInvalidTrafficData([])}} header={addInvalidRule?'Set Invalid Traffic Deduction':'Invalid Traffic By Months'}>
                        <div className={classes.main_middle_bottom_admin_popup}>
                            {invalidTrafficLoading ? (
                                    <div className={classes.center_spinner_div}>
                                        <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>
                                    </div>
                            ):(
                                <>
                                    {!addInvalidRule && 
                                        <div className={classes.sectionContainer_top}>
                                            <div className={classes.invalid_action_div}>
                                                <div className={report_classes.search_div_30}>
                                                    <img src={search_gray_icon} alt="Search"/>
                                                    <input disabled={invalidTrafficLoading?true:invalidTrafficSearchValue==='' && invalidTrafficData.length===0?true:false} placeholder='Search Month...' type='text' onChange={(e)=> setInvalidTrafficSearchValue(e.target.value)} value={invalidTrafficSearchValue}/>
                                                </div>
                                                <button className={classes.default_green_btn} onClick={()=>{setAddInvalidRule(true)}}>Add Invalid Month</button>
                                            </div>
                                        </div>
                                    }

                                    <div className={classes.sectionContainer}>
                                        {addInvalidRule ? (
                                            <>
                                                <div className={classes.form_div_no_padding}>
                                                    <div className={classes.form_child_div}>
                                                        <div>
                                                            <label>Month</label>
                                                            <div className={classes.search_select_div_100}>
                                                                <input onChange={(e)=> {setInvalidMonth(e.target.value)}} type="month" name="invalidMonth" min="2023-01" value={invalidMonth}/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label>Invalid Traffic</label>
                                                            <input name="invalid_traffic" placeholder="(%) of value" type='number' min={0} max={100} onChange={(e)=>setInvalidTraffic(e.target.value)} value={invalidTraffic}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.form_child_div}>
                                                        <div>
                                                            <label>Site</label>
                                                            {renderMonthsSelectDiv(trackingDomains, invalidSite, setInvalidSite, setGetPrArRevs)}
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className={report_classes.bottom_buttons}>
                                                    <button className={classes.default_green_btn} onClick={handleAddInvalidRule}>Add</button>
                                                    <button className={classes.default_red_btn} onClick={()=>{setAddInvalidRule(false)}}>Cancel</button>
                                                </div>

                                            </>
                                        ):(
                                            <div className={(BreakdownRevenueReport?.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                                <ResponsiveTable loadingResults={false}>
                                                    <thead><ReportHeaders header_names={invalid_traffic_table_headers}/></thead>
                                                    {(invalidTrafficData.length===0)?(
                                                        <span className={classes.noData}>No Invalid Traffic Data</span>
                                                    ):(
                                                        <tbody className={user_report_classes.table_data}>{renderInvalidTraffic()}</tbody>
                                                    )}
                                                </ResponsiveTable>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </PopUpCard>
                )}




                {/* main componenet and fragments */}
                {loadingResults ? (
                    <LoadingTable/>
                ):(
                    <>
                        <div className={classes.main_top}>
                            <div className={classes.main_title}>
                                <div className={classes.heading}>Admin Payments</div>
                            </div>
                        </div>

                        <div className={classes.switch_fragment_div_parent}>
                            <div className={classes.switch_fragment_div}>
                                <div id='payment_request' className={defaultFragment==="payment_request"?classes.active_fragment:classes.inactive_fragment} onClick={(e) => setDefaultFragment(e.target.id)}>Requests</div>
                                <div id='processed_request' className={defaultFragment==="processed_request"?classes.active_fragment:classes.inactive_fragment} onClick={(e) => setDefaultFragment(e.target.id)}>Reviewed</div>
                                <div id='revenue' className={defaultFragment==="revenue"?classes.active_fragment:classes.inactive_fragment} onClick={(e) => setDefaultFragment(e.target.id)}>Revenue</div>
                            </div>
                            {defaultFragment === "payment_request" && (
                                <div className={classes.export_div} onClick={handleExport}>
                                    <img src={export_icon} alt=""/>
                                    <div>Export</div>
                                </div>
                            )}
                            {defaultFragment === "revenue" && (
                                <div className={classes.invalid_revenue_div} onClick={handleInvalidRevenue}>
                                    <img src={invalid_revenue_icon} alt=""/>
                                    <div>Set Invalid</div>
                                </div>
                            )}
                        </div>

                        
                        {defaultFragment === "payment_request" && (
                            <div className={classes.main_middle_admin}>
                                <div className={classes.main_middle_top_admin}>
                                    <Col xl={6} md={8} sm={12} xs={24}><BillingCard title="Payment Requests" value={`${PaymentRequestData.length}`} tooltip={htmlparser("Requests Send By User <br> But Not Reviewed")}/></Col>
                                    <Col xl={6} md={8} sm={12} xs={24}><BillingCard title='Requsted Amount' value={`$${PaymentRequestSummary.requested_amount.toLocaleString()}`} tooltip="Reuested amount" /></Col>
                                    {/* <Col xl={6} md={8} sm={12} xs={24}><BillingCard title='Approved' value={`${PaymentRequestSummary.approved} / $ ${PaymentRequestSummary.approved_amount.toLocaleString()}`} tooltip="Paid" /></Col> */}
                                    {/* <Col xl={6} md={8} sm={12} xs={24}><BillingCard title='Not Sent' value={`${PaymentRequestSummary.not_sent} / $ ${PaymentRequestSummary.not_sent_amount.toLocaleString()}`} tooltip={htmlparser("User Generated Revenue<br>But No Requests Sent Yet")}/></Col> */}
                                </div>

                                <div className={classes.main_middle_bottom_admin}>
                                    {/* pending prs */}
                                    <div className={classes.sectionContainer}>
                                        <div className={classes.sectionContainer_top}>
                                            <h6 className={classes.header}>Payment Requests</h6>
                                            <div className={classes.search_select_div}>
                                                <div className={report_classes.search_div}>
                                                    <img src={search_gray_icon} alt="Search"/>
                                                    <input disabled={prSearchValue==='' && PaymentRequestData.length===0?true:false} placeholder='Search requests...' type='text' onChange={(e)=> setPrSearchValue(e.target.value)} value={prSearchValue}/>
                                                </div>
                                                {renderMonthsSelectDiv(months, selectedMonth, setSelectedMonth, setGetPrArRevs)}
                                            </div>
                                        </div>
                                        <div className={(PaymentRequestData.length===0)?classes.local_table_div:user_report_classes.table_div}>
                                            <ResponsiveTable loadingResults={fragmentLoading}>
                                                <thead><ReportHeaders header_names={pending_pr_table_headers}/></thead>
                                                {(PaymentRequestData.length===0)?(
                                                    <span className={classes.noData}>No Payment Request Data</span>
                                                ):(
                                                    <tbody className={user_report_classes.table_data}>{renderPaymentRequests()}</tbody>
                                                )}
                                            </ResponsiveTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {defaultFragment === "processed_request" && (
                            <div className={classes.main_middle_admin}>
                                <div className={classes.main_middle_top_admin}>
                                    <Col xl={6} md={8} sm={24} xs={24}><BillingCard title='Paid' value={`${ApprovedRequestSummary.paid} / $ ${ApprovedRequestSummary.paid_amount.toLocaleString()}`} tooltip="Successful Transactions"/></Col>
                                    <Col xl={6} md={8} sm={24} xs={24}><BillingCard title='Denied' value={`${ApprovedRequestSummary.denied} / $ ${ApprovedRequestSummary.denied_amount.toLocaleString()}`} tooltip="Denied Requests"/></Col>
                                </div>

                                <div className={classes.main_middle_bottom_admin}>
                                    {/* processed pr */}
                                    <div className={classes.sectionContainer}>
                                        <div className={classes.sectionContainer_top}>
                                            <h6 className={classes.header}>Approved Requests</h6>
                                            <div className={classes.search_select_div}>
                                                <div className={report_classes.search_div}>
                                                    <img src={search_gray_icon} alt="Search"/>
                                                    <input disabled={processedSearchValue==='' && ApprovedRequestData.length===0?true:false} placeholder='Search processed...' type='text' onChange={(e)=> setProcessedSearchValue(e.target.value)} value={processedSearchValue}/>
                                                </div>
                                                {renderMonthsSelectDiv(months, selectedMonth, setSelectedMonth, setGetPrArRevs)}
                                            </div>
                                        </div>
                                        <div className={(ApprovedRequestData.length===0)?classes.local_table_div:user_report_classes.table_div}>
                                            <ResponsiveTable loadingResults={fragmentLoading}>
                                                <thead><ReportHeaders header_names={processed_pr_table_headers}/></thead>
                                                {(ApprovedRequestData.length===0)?(
                                                    <span className={classes.noData}>No Processed Payment Request Data</span>
                                                ):(
                                                    <tbody className={user_report_classes.table_data}>{renderProcessedRequests()}</tbody>
                                                )}
                                            </ResponsiveTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {defaultFragment === "revenue" && (
                            <div className={classes.main_middle_admin}>
                                <div className={classes.main_middle_top_admin}>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title="GA4 Revenue" value={`$ ${round(RevenueReportSummary.ga4_revenue_amount, 2).toLocaleString()}`} tooltip="Total GA4 Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='Google Ad Manager' value={`$ ${round(RevenueReportSummary.google_ad_manager_amount, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='Google Ad Manager Ded' value={`$ ${round(RevenueReportSummary.google_ad_manager_amount_deduct, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                </div>
                                <div className={classes.main_middle_top_admin}>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='Total Aff' value={`$ ${round(RevenueReportSummary.total_aff, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='Total LV' value={`$ ${round(RevenueReportSummary.total_lv, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title="Revenue" value={`$ ${round(RevenueReportSummary.total_revenue, 2).toLocaleString()}`} tooltip="Total LinkVantage Revenue"/>
                                    </Col>
                                </div>
                                <div className={classes.main_middle_top_admin}>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='Content Arbritage' value={`$ ${round(RevenueReportSummary.content_arbitage, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title='CPA Revenue' value={`$ ${round(RevenueReportSummary.cpa, 2).toLocaleString()}`} tooltip="Total Google Ad Manager Revenue"/>
                                    </Col>
                                    <Col xl={6} md={8} sm={12} xs={24}>
                                        <BillingCard title="Total Revenue" value={`$ ${round(RevenueReportSummary.network_rev, 2).toLocaleString()}`} tooltip="Total LinkVantage Revenue"/>
                                    </Col>
                                </div>


                                {/* offer breakdown revenue report for each affiliate user */}
                                {showBreakdownReportPopup ? (
                                    <div className={classes.main_middle_bottom_admin}>
                                        {/* all reports by month */}
                                        <div className={classes.sectionContainer}>
                                            <div className={classes.sectionContainer_top}>
                                                <div className={classes.main_title_custom} onClick={()=> {setShowBreakdownReportPopup(false); setBreakdownRevenueReport([]); setBreakdownRevenueReportBackup([])}}>
                                                    <img className={classes.go_back_btn} src={go_back_icon} alt='<'/>
                                                    <div className={classes.header}>Affiliate Revenue Report</div>
                                                </div>

                                                <div className={classes.search_select_div}>
                                                    <div className={classes.search_select_div}>
                                                        <div className={report_classes.search_div}>
                                                            <img src={search_gray_icon} alt="Search"/>
                                                            <input disabled={breakdownReportLoading? true: breakdownSearchValue==='' && BreakdownRevenueReport.length===0?true:false} placeholder='Search breakdown...' type='text' onChange={(e)=> setBreakdownSearchValue(e.target.value)} value={breakdownSearchValue}/>
                                                        </div>
                                                    </div>
                                                    <div className={classes.search_select_div_100}>
                                                        <DateRangePicker 
                                                            // format="MMM yyyy" 
                                                            caretAs={BsCalendar2MonthFill} 
                                                            limitStartYear='5'
                                                            limitEndYear='5'
                                                            placement="bottomEnd"
                                                            placeholder="Select Month"
                                                            disabled={breakdownReportLoading?true:false}

                                                            value={selectionRange}
                                                            onChange={handleSelect}
                                                            onOk={handleSelect}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {breakdownReportLoading ? (
                                                <div className={classes.center_spinner_div}>
                                                    <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>
                                                </div>
                                            ):(
                                                <div className={classes.sectionContainer}>
                                                    <div className={(BreakdownRevenueReport.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                                        <ResponsiveTable loadingResults={false}>
                                                            <thead><ReportHeaders header_names={breakdown_revenue_table_headers}/></thead>
                                                            {(BreakdownRevenueReport.length===0)?(
                                                                <span className={classes.noData}>No Breakdown Revenue Reports</span>
                                                            ):(
                                                                <tbody className={user_report_classes.table_data}>{renderBreakdownRevenueReports()}</tbody>
                                                            )}
                                                        </ResponsiveTable>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ):(
                                    <div className={classes.main_middle_bottom_admin}>
                                        {/* all reports by month */}
                                        <div className={classes.sectionContainer}>
                                            <div className={classes.sectionContainer_top}>
                                                <h6 className={classes.header}>Revenue Reports</h6>

                                                <div className={classes.search_select_div}>
                                                    <div className={report_classes.search_div}>
                                                        <img src={search_gray_icon} alt="Search"/>
                                                        <input disabled={billingReportSearch==='' && RevenueReport.length===0?true:false} placeholder='Search revenue...' type='text' onChange={(e)=> setBillingReportSearch(e.target.value)} value={billingReportSearch}/>
                                                    </div>
                                                </div>

                                                <div className={classes.search_select_div_100}>
                                                    <DateRangePicker 
                                                        // format="MMM yyyy" 
                                                        caretAs={BsCalendar2MonthFill} 
                                                        limitStartYear='5'
                                                        limitEndYear='5'
                                                        placement="bottomEnd"
                                                        placeholder="Select Month"

                                                        value={selectionRange}
                                                        onChange={handleSelect}
                                                        onOk={handleSelect}
                                                    />
                                                </div>
                                            </div>

                                            <div className={(RevenueReport.length===0)?classes.local_table_div:user_report_classes.table_div}>
                                                <ResponsiveTable loadingResults={fragmentLoading}>
                                                    <thead><ReportHeaders header_names={revenue_table_headers}/></thead>
                                                    {(RevenueReport.length===0)?(
                                                        <span className={classes.noData}>No Revenue Reports</span>
                                                    ):(
                                                        <tbody className={user_report_classes.table_data}>{renderRevenueReports()}</tbody>
                                                    )}
                                                </ResponsiveTable>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default AdminBilling
