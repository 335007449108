import classes from './Dashboard.module.scss'

// packages
import { useState, useEffect } from 'react'

import DashboardCard from "../Cards/DashboardCard"
import LoadingTable from '../LoadingTable/LoadingTable'

import conversions_icon from '../../assets/icons/conversions_white.svg'
import revenue_icon from '../../assets/icons/dollar_white.svg'
import users_solid from '../../assets/icons/users-new-solid.svg'
import ad_impression_icon from '../../assets/icons/eye_white.svg'
// import ad_impression_icon from '../../assets/images/ad_impression.png'

import moment from 'moment'
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';

import addDays from 'date-fns/addDays';
import { Grid, Row, Col } from 'rsuite';
import { useCookies } from 'react-cookie';
import { Chart } from "react-google-charts";

import { apiHandler } from '../../utils/helper_functions'
import useWindowSize from '../../assets/hooks/useWindowSize.js'
import { DummyTable } from './dummy-table.js';



const Dashboard = () => {
    let { width } = useWindowSize()
    // const names = useSelector(state => state.ui.language.values.dashboardContent)

    // const insight_card_texts = names.insight_card_texts
    // const date_options = useSelector(state => state.ui.language.values.date_options)

    // const userData = useSelector(state => state.user)
    // const showAdminInterface = useSelector(state => state.ui.showAdminInterface)
    // const api_token = useSelector(state => state.user.token)

    // const [showDateRange, setShowDateRange] = useState(false)

    // staes 
    // const [queryField, setQueryField] = useState(date_options.last_week)
    // const [lineChartLabels, setLineChartLabels] = useState(userData.totalByMonth.map((item) => Object.keys(item)).reverse())
    // const [lineChartData, setLineChartData] = useState([userData.totalByMonth.map((item) => Object.values(item)[0].NIS).reverse()])
    // const [barChartLabels, setBarChartLabes] = useState(showAdminInterface ? [] : userData.websites.map((value) => value.domain))
    // const [barChartData , setBarChartData] = useState(showAdminInterface ? [] : [userData.websites.map((value) => value.earned)])
    // const [adclicks, setAdClicks] = useState(0)
    // const [avgEpc, setAvgEpc] = useState(0)
    

    const [loadingResults, setLoadingResults] = useState(true)

    const [totalSessions, setTotalSessions] = useState(0)
    const [totalAdImpressions, setTotalAdImpressions] = useState(0)
    const [totalAdRevenue, setTotalAdRevenue] = useState(0)
    const [totalConversions, setTotalConversions] = useState(0)
    const [totalConversionsRev, setTotalConversionsRev] = useState(0)
    
    const [sessionMap, setSessionMap] = useState([])
    const [adImpMap, setAdImpMap] = useState([])
    const [conversionMap, setConversionMap] = useState([])
    const [incomeMap, setIncomeMap] = useState([])

    // toast error states
    const [error, setError] = useState(null)

    const localRedirect = (url) =>{
        window.location.href = url
    }

    const predefinedRanges = [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          appearance: 'default'
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          appearance: 'default'
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          appearance: 'default'
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          appearance: 'default'
        },
        {
          label: 'All time',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
          appearance: 'default'
        }
    ];
    
    const [cookies, setCookie] = useCookies(['report_dates'])
    const [selectionRange, setSelectionRange] = useState([
        cookies.report_dates?new Date(cookies.report_dates[0]):new Date(new Date().setDate(new Date().getDate() - 7)),
        cookies.report_dates?new Date(cookies.report_dates[1]):new Date()
    ])


    let handleSelect = (dates) => {
        setCookie('report_dates', dates)
        setSelectionRange(dates)
        setLoadingResults(true)
        loadDashboardData(dates)
    }

    const saveCardData = (data) => {
        if( data?.cards ){
            let cardData = data?.cards;
            setTotalSessions(cardData['sessions'])
            setTotalAdImpressions(cardData['ad_impressions'])
            setTotalAdRevenue(cardData['total_ad_revenue'])
            // setAcceptedConversions(cardData['accepted_conversaions'])
            // setDeniedConversions(cardData['denied_conversaions'])
            setTotalConversions(cardData['conversions'])
            setTotalConversionsRev(cardData['conversions_rev'])
        }
    }
    
    const saveGraphs = async (data) => {
        let sessionMap = [["Date", "Sessions"]]
        let adImpMap = [["Date", "Impressions"]]
        let conversionMap = [["Date", "Conversions"]]
        let revenueMap = [["Date", "Revenue"]]

        await Object.keys(data.reports_map).forEach( date => {
            sessionMap.push([date, data.reports_map[date].sessions])
            adImpMap.push([date, data.reports_map[date].ad_impressions])
            revenueMap.push([date, data.reports_map[date].total_ad_revenue])
            conversionMap.push([date, data.reports_map[date].conversions])
        })
        setSessionMap(sessionMap)
        setAdImpMap(adImpMap)
        setConversionMap(conversionMap)
        setIncomeMap(revenueMap)
    }

    const loadDashboardData = async (dates) => {
        let start_date = moment(selectionRange[0]).format('YYYY-MM-DD')
        let end_date =  moment(selectionRange[1]).format('YYYY-MM-DD')
        if(dates){
            start_date = moment(dates[0]).format('YYYY-MM-DD')
            end_date =  moment(dates[1]).format('YYYY-MM-DD')
        }

        const response = await apiHandler('/dashboard_reports?start_date='+start_date+'&end_date='+end_date, 'GET', null, setError)
        if(response){
            saveCardData(response)
            saveGraphs(response)
        }
        setLoadingResults(false)
    }

    const isValidToken = async() => {
        let token = localStorage.getItem('access_token')
        if(!token){ localRedirect('/login') }

        const response = await apiHandler('/is_valid_token', 'GET', null, setError)
        if(response){
            console.log('is_valid_token :', JSON.stringify(response))
        }
    }
    
    // const [width, setWidth] = useState(window.innerWidth)

    // function handleWindowSizeChange() {
    //     setWidth(window.innerWidth)
    // }
    // window.addEventListener('resize', handleWindowSizeChange)
    // return () => {
    //     window.removeEventListener('resize', handleWindowSizeChange)
    // }

    useEffect(()=>{
        isValidToken()
        loadDashboardData()
        document.title = "LinkVantage | Dashboard";
    }, [])

    const numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                {loadingResults ? ( 
                    <LoadingTable/> 
                ):(
                    <Grid fluid className={classes.grid_div}>
                        <Row className={`${classes.show_grid_div} show-grid`} style={{marginBottom:"1em"}}>
                            {width >= 992 && <Col lg={6} xl={8} xxl={6}></Col>}

                            <Col xxl={12} xl={8} lg={24} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                                {width < 992 ? (
                                    <DateRangePicker 
                                        showOneCalendar 
                                        appearance='default'
                                        ranges={predefinedRanges}
                                        value={selectionRange}
                                        onChange={handleSelect}
                                    />
                                ):(
                                    <DateRangePicker
                                        appearance='default'
                                        ranges={predefinedRanges}
                                        style={{ width: 300 }}
                                        value={selectionRange}
                                        onChange={handleSelect}
                                    />
                                )}
                            </Col>
                        </Row>


                        <Row className={`${classes.show_grid_div} show-grid`}>
                            <Col xl={6} md={8} sm={12} xs={24}>
                                <DashboardCard imageSize="20px" image={users_solid} title={"Total Sessions"} value={totalSessions?.toLocaleString()}/>
                            </Col>
                            <Col xl={6} md={8} sm={12} xs={24}>
                                <DashboardCard image={revenue_icon} title={"Ad Revenue"} value={"$"+numberWithCommas(totalAdRevenue?.toLocaleString())}/>
                            </Col>
                            <Col xl={6} md={8} sm={12} xs={24}>
                                <DashboardCard image={revenue_icon} title={"Conversion Revenue"} value={"$"+numberWithCommas(totalConversionsRev?.toLocaleString())}/>
                            </Col>
                            <Col xl={6} md={8} sm={12} xs={24}>
                                <DashboardCard image={revenue_icon} title={"Total Revenue"} value={"$"+numberWithCommas((totalAdRevenue+totalConversionsRev)?.toLocaleString())}/>
                            </Col>
                        </Row>


                        {/* plot section */}
                        <Row className={`${classes.show_grid_div} show-grid`}>
                            <Col className={classes.col} xl={12} md={24} s={24}> 
                                {/* {sessionMap.length>1? */}
                                <Chart
                                className={classes.chart}
                                chartType="AreaChart"
                                legendToggle
                                width="100%"
                                height="400px"
                                data={sessionMap.length>1?sessionMap:[["Date", "Sessions"], [0, 0]]}
                                options={{                                
                                    vAxis:{format:"decimal", textStyle:{color: '#182444'}, ...(sessionMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                    hAxis: {textPosition:'none', slantedText:true, textStyle:{color:'#182444'}, ...(sessionMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                    // 'chartArea': {'width': '80%', 'height': '70%', borderRadius:"2%"},
                                    tooltip: {textStyle: {width:"100%"},  isHtml:true},
                                    title: "Total Sessions",
                                    curveType: "function",
                                    legend: { position: "bottom", alignment:'start'}
                                    
                                }}
                                />
                                 {/* :
                                <div className='no-chat-data-div'>
                                    <DummyTable/>
                                </div>
                                }  */}
                            </Col> 

                            <Col className={classes.col} xl={12} md={24} s={24} > 
                                {/* {adImpMap.length>1? */}
                                <Chart
                                    className={classes.chart}
                                    chartType="AreaChart"
                                    width="100%"
                                    height="400px"
                                    data={adImpMap.length>1?adImpMap:[["Date", "Impressions"], [0, 0]]}
                                    // data={[["av", "av"], [0, 0]]}
                                    format="0"
                                    options={{                   
                                        vAxis:{format:"decimal", textStyle: {color: '#182444'}, ...(adImpMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        hAxis: {textPosition:'none', slantedText: true, textStyle: {color: '#182444'}, maxValue:0, ...(adImpMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        // 'chartArea': {'width': '75%', 'height': '70%'},
                                        tooltip:{textStyle: {},  isHtml:true},
                                        title: "Ad Impressions",
                                        curveType: "function",
                                        legend: { position: "bottom", alignment:'start'}
                                    }}
                                />
                                {/* :
                                <div className='no-chat-data-div'>
                                    No Ad Impressions Data
                                </div>
                                } */}
                            </Col> 

                            <Col className={classes.col} xl={12} md={24} s={24} > 
                                {/* {incomeMap.length>1? */}
                                <Chart
                                    className={classes.chart}
                                    chartType="AreaChart"
                                    width="100%"
                                    height="400px"
                                    // data={incomeMap}
                                    data={incomeMap.length>1?incomeMap:[["Date", "Revenue"], [0, 0]]}
                                    options={{                   
                                        vAxis:{format:"currency", textStyle: {color: '#182444'}, ...(incomeMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        hAxis: {textPosition:'none', slantedText: true, textStyle: {color: '#182444'}, ...(incomeMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        // 'chartArea': {'width': '75%', 'height': '70%'},
                                        
                                        tooltip:{textStyle: {},  isHtml:true},
                                        title: "Ad Revenue",
                                        curveType: "function",
                                        legend: { position: "bottom", alignment:'start'}
                                    }}
                                />
                                {/* :
                                <div className='no-chat-data-div'>
                                    No Revenue Data
                                </div>
                                } */}
                            </Col> 

                            <Col className={classes.col} xl={12} md={24} s={24} > 
                                {/* {conversionMap.length>1? */}
                                <Chart
                                    className={classes.chart}
                                    chartType="AreaChart"
                                    width="100%"
                                    height="400px"
                                    data={conversionMap.length>1?conversionMap:[["Date", "Conversions"], [0, 0]]}
                                    options={{                   
                                        vAxis:{format:"0.00", ...(conversionMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        hAxis: {textPosition:'none', slantedText: true, textStyle: {color: '#182444'}, ...(conversionMap.length>1)?{}:{ticks: [0, 1, 2, 3, 4, 5]}},
                                        // 'chartArea': {'width': '80%', 'height': '70%'},
                                        tooltip:{textStyle: {},  isHtml:true},
                                        title: "Conversions",
                                        curveType: "function",
                                        legend: { position: "bottom", alignment:'start'}
                                    }}
                                />
                                {/* :
                                <div className='no-chat-data-div'>
                                    No Conversion Data
                                </div>
                                } */}
                            </Col> 
                        </Row> 
                    </Grid>
                )}
            </div>
        </div>
    )
}

export default Dashboard;