import classes from './NavBarItem.module.scss'

import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'


const NavBarItem = (props) => {
    const param = useParams()
    let isActive = param.page === props.route
    
    const minimizeNavBar =  useSelector(state => state.ui.minimizeNavBar)
    const awaitingOffersNumber = useSelector(state => state.ui.awaitingOffersNumber)

    const name = props.name ? props.name : 'Please Add Name'
    const image_src = props.image_src
    const image_alt = props.image_alt?props.image_alt:'Please Add Alt'

    const route = `/${props.route}`


    return (
        <NavLink onClick={props.changeSideBar} to={route} className={!isActive ? classes.container : classes.selected}> 
            <div className={classes.image}><img src={image_src} alt={image_alt}/></div>
            {!minimizeNavBar && <div className={classes.name}>{name}</div>}

            {awaitingOffersNumber > 0 && props.route === 'admin-approval' && 
                <div className={classes.notification_div}>
                    <div className={classes.notification}>
                        {awaitingOffersNumber}
                    </div>
                </div>}
        </NavLink>
    )
}

export default NavBarItem