import classes from './Reports.module.scss'
import offer_classes from '../Offers/Offers.module.scss'

import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

import CustomButton from "../Customs/CustomButton/CustomButton"
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'


import { Tr, Td } from 'react-super-responsive-table'
import { offers_table_headers_limited, ReportHeaders, getStatusDiv, getStatusText, displayAllDataOfTargetOffer, apiHandler } from '../../utils/helper_functions'
import { uiActions } from '../../store/ui-slice'







const AdminApproval = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch()
    
    // approval states
    const [Data, setData] = useState([])
    

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    

    // const [modelOpen, setModalOpen] = useState(false)
    // const handleOpen = () => setModalOpen(true)
    // const handleClose = () => setModalOpen(false)
    // const [selectedData, setSelectedData] = useState({})


    // show user offer section
    const [targetApproval, setTargetApproval] = useState()
    const [showUserOfferDiv, setShowUserOfferDiv] = useState(false)
    const [offerData, setOfferData] = useState([])
    const [fetchApprovalOffersAgain, setFetchApprovalOffersAgain] = useState(false)

    // each offer div state
    const [showEachOfferDiv, setShowEachOfferDiv] = useState(false)
    const [targetOffer, setTargetOffer] = useState()


    // loading animation section
    const [loading, setLoading] = useState(false)
    






    // ###################### approval functions ######################
    const getApprovals = async () => {
        setLoadingResults(true)

        let url = `${API_URL}/api/v1/approval`;
        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.approvals){
                setData(response.data.approvals)
                dispatch(uiActions.setAwaitingOffersNumber(response.data.awaiting_approvals_len))

                setFetchApprovalOffersAgain(false)
            }
            setLoadingResults(false)

        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
            }
        })
    }

    useEffect(() => {
        document.title = "LinkVantage | Admin Users"
        getApprovals()
    },[])

 


    const handleChangeApprovalStatus = async (data, target_status) => {  
        setLoadingResults(true)
        console.log('----------', data, target_status)

        const response = await apiHandler('/approval-status', 'POST', {
            targetApprovalOffer: data['offer'],
            targetUser: data['user'],
            targetStatus: target_status,
            allData:false,
        }, setError)

        if(response){
            console.log("approval status data :", response, response.approvals)
            setData(response.approvals)
            dispatch(uiActions.setAwaitingOffersNumber(response.awaiting_approvals_len))
    
            toast.success(`The offer: ${data['offer_name']} for user: ${data['user_name']} is ${target_status==='active'?'approved':'denied'}`)
            setSuccess(true)
        }
        // toast.error(`The operation on offer: ${data['offer_name']} for ${data['user_name']} is failed`)
        setLoadingResults(false)
    }




    const handleShowUsersOffer = (item) => {
        // console.log('selected user item data is :', item.offer, item.user)
        setTargetApproval(item)
        setShowUserOfferDiv(true)
    }


    const report_table_headers = ["#", "Offer", "User", "Status", "Request On", "Actions"]
    const renderApprovals = () => {
        let approvalData = []
        Data.forEach((data, index) =>  {
            approvalData.push(
                <tr key={index}>
                <td>{index+1}</td>
                <td>{data.offer_name}</td>
                <td className={classes.cursor_pointer} onClick={() => handleShowUsersOffer(data)}>{data.user_name}</td>
                <td>{getStatusDiv(data.offer_status)}</td>
                <td>{data.request_on}</td>
                <td>
                    <div className={classes.custom_icon_div}>
                        <CustomButton color='green' name="Approve" type='button' onClick={()=> {handleChangeApprovalStatus(data, 'active')}} />
                        <CustomButton color='red' name="Deny" type='button' onClick={()=> {handleChangeApprovalStatus(data, 'apply')}} />
                    </div>
                </td>
            </tr>)
        })
        if(Data.length===0){
            approvalData.push(<tr key={0}>
                <td style={{textAlign:"center"}} colSpan={6}>No Data</td>
            </tr>)
        }
        return approvalData
    }








    // ##################### user offer section #####################
    useEffect(() => {
        if (showUserOfferDiv && targetApproval){
            fetchTargetUserOffers(targetApproval['user'], 1, 10)
        }
    }, [showUserOfferDiv])


    const handleShowUserOfferDivClose = () => {
        setShowUserOfferDiv(false)
        setTargetApproval()
        setOfferData([])

        setShowEachOfferDiv(false)
        setTargetOffer()

        if (fetchApprovalOffersAgain){
            getApprovals()
        }
    }


    const fetchTargetUserOffers =  async (target_id, page, limit) => {
        setLoading(true)
        const response = await apiHandler('/user-offers', 'POST', {
                user: target_id,
                perpage: limit,
                pagenumber: page,
            }, setError)

        if(response){
            console.log("=== user offers in approval ==>", response.offers)
            setOfferData(response.offers)
        }
        setLoading(false)
    }

    
    const handleChangeOfferStatusForUser = async (item, target_status) => {
        setLoading(true)
        console.log("Changed offer status for user :", item, target_status)

        const response = await apiHandler('/approval-status', 'POST', {
            targetApprovalOffer: item['offer'],
            targetUser: item['user'],
            targetStatus: target_status,
            allData: true,
        }, setError)

        if(response){
            console.log("Target offer target for approval is :", response.approvals)
            setOfferData(response.approvals)
            dispatch(uiActions.setAwaitingOffersNumber(response.awaiting_approvals_len))
            toast.success(`The status offer: ${item['offer_name']} for user: ${item['user_name']} is ${getStatusText(target_status)}`)
            setSuccess(true)
            
            // fetch all approval offers again
            setFetchApprovalOffersAgain(true)
        }
        setLoading(false)
    }



    const renderOffers = () => {
        let offerDataHtml = offerData.map((item, index) => (
            <Tr key={index}>
                <Td>{index+1}</Td>
                <Td className={classes.cursor_pointer} onClick={() => {
                    setShowEachOfferDiv(true)
                    setTargetOffer(item)
                }}>{item.offer_name}</Td>
                <Td>
                    <select
                        name='status'
                        onChange={(e)=>handleChangeOfferStatusForUser(item, e.target.value)}
                        style={{
                            borderStyle: "none",
                            outline: "none",
                            fontSize: "1em",
                            color: "#374957",
                            width: "100%",
                        }}>    

                        {item.offer_status==='public'?(
                            <option key="Public" value="public" disabled selected={item.offer_status==='public'}>Public</option>
                        ):(
                            <>
                            <option key="Apply To Run" value="apply" selected={item.offer_status==='apply'}>Apply To Run</option>
                            <option key="Pending" value="pending" selected={item.offer_status==='pending'}>Pending</option>
                            <option key="Active" value="active" selected={item.offer_status==='active'}>Active</option>
                            </>
                        )}
                    </select>
                </Td>
            </Tr>
        ))
        return offerDataHtml
    }
    


    return (
    <>  
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_middle}>
                    {showUserOfferDiv && targetApproval  && 
                        <div className={offer_classes.extra_padding}>
                            <PopUpCard header={`Offers - ${targetApproval['user_name']}`} loading={loading} toggle={handleShowUserOfferDivClose}>
                                {showEachOfferDiv?(
                                    <>{displayAllDataOfTargetOffer(targetOffer, loading, showEachOfferDiv, setShowEachOfferDiv, showEachOfferDiv, showEachOfferDiv, showEachOfferDiv, setShowEachOfferDiv, setTargetOffer, true)}</>
                                ):(
                                    <ResponsiveTable loadingResults={loading}>
                                            <thead><ReportHeaders header_names={offers_table_headers_limited}/></thead>
                                            <tbody className={classes.table_data}>{renderOffers()}</tbody>
                                    </ResponsiveTable>
                                )}
                            </PopUpCard>
                        </div>
                    }

                    <div className={classes.table_div}>
                        {success && <ToastContainer/>}
                        {error && <ToastContainer/>}
                        {/* approvals pending section */}
                        <ResponsiveTable loadingResults={loadingResults}>
                            <thead><ReportHeaders header_names={report_table_headers}/></thead>
                            <tbody className={classes.table_data}>
                                {renderApprovals()}
                            </tbody>
                        </ResponsiveTable>
                    </div>
                </div>                  
            </div>
        </div>
    </>
    )
}

export default AdminApproval;