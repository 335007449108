import classes from './Reports.module.scss';

import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import SearchBar from '../SearchBar/SearchBar';
import CustomButton from '../Customs/CustomButton/CustomButton';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import ReportHeaders from './ReportHeaders';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment'
import axios from 'axios'
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math';
import goback from '../../assets/icons/go_back.svg'

import { Link } from 'react-router-dom';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import { Grid, Row, Col } from 'rsuite';
import { useCookies } from 'react-cookie';



const OfferReport = (props) => {

    const [offerId, setOfferId] = useState(props.offerId)
    const [offerName, setOfferName] = useState("")

    // required variables and names
    const names = useSelector(state => state.ui.language.values.reportsContent)
   
    const report_table_headers = ["UTM Source", "UTM Medium", "UTM Campaign", "Users", "Sessions", "Avg, EPC", "Ad Clicks", "Ad Imp.", "Total Revenue"]
    
    const API_URL = process.env.REACT_APP_API_URL
    
    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)
    const [Data, setData] = useState([])

    const predefinedRanges = [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          appearance: 'default'
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          appearance: 'default'
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          appearance: 'default'
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          appearance: 'default'
        },
        {
          label: 'All time',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
          appearance: 'default'
        }
    ];
    
    const [cookies, setCookie] = useCookies(['report_dates'])
    const [selectionRange, setSelectionRange] = useState([
        cookies.report_dates?new Date(cookies.report_dates[0]):new Date(new Date().setDate(new Date().getDate() - 7)),
        cookies.report_dates?new Date(cookies.report_dates[1]):new Date()
    ])

    let handleSelect = (dates) => {
        setCookie('report_dates', dates)
        setSelectionRange(dates)
        getReports(dates)
    }

    const getReports = async (dates) => {
        setLoadingResults(true)
        let start_date = moment(selectionRange[0]).format('YYYY-MM-DD')
        let end_date =  moment(selectionRange[1]).format('YYYY-MM-DD')
        if(dates){
            start_date = moment(dates[0]).format('YYYY-MM-DD')
            end_date =  moment(dates[1]).format('YYYY-MM-DD')
        }
        let url = `${API_URL}/api/v1/reports/${offerId}?start_date=${start_date}&end_date=${end_date}`;

        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.reports){
                setData(response.data.reports)
            }
            
            if(response.data.offerData &&  response.data.offerData.name){
                setOfferName(response.data.offerData.name)
            }
            setLoadingResults(false)
        })
        .catch((error) => {
            setLoadingResults(false)
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    useEffect(() => {
        getReports()
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    },[])

    const renderReports = () =>{
        let reportsData = []
        Data.forEach((data, index) =>  {
            reportsData.push(<tr key={index}>
                <td>{data.utm_src}</td>
                <td>{data.utm_medium}</td>
                <td>{data.utm_campaign}</td>
                <td>{round(data.totalUsers, 0)}</td>
                <td>{round(data.sessions, 0)}</td>
                <td>{round(data.avg_epc, 2)}</td>
                <td>{round(data.ad_clicks, 0)}</td>
                <td>{round(data.ad_impressions, 0)}</td>
                <td>${round(data.totalRevenue, 2)}</td>
            </tr>)
        })
        if(Data.length===0){
            reportsData.push(<tr key={0}>
                <td style={{textAlign:"center"}} colSpan={9}>No Data</td>
            </tr>)
        }
        return reportsData
    }
    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    
    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <Grid fluid style={{width:"100%"}}>
                    <Row className="show-grid">
                        <Col lg={18} xl={18} xxl={8}>
                            <div className={classes.main_title}>
                                <div className="heading"><Link to={(props.return_link)?props.return_link:"/offers"}><img src={goback} alt='Go Back' style={{padding:"0em 1em"}}/></Link>
                                Offer Report{(offerName)? ' for "' + (offerName) +'"' : ""}</div>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                            <DateRangePicker
                                appearance='default'
                                ranges={predefinedRanges}
                                style={{ width: 300 }}
                                value={selectionRange}
                                onChange={handleSelect}
                            />
                        </Col>
                    </Row>
                </Grid>
                
                <div className={classes.main_middle}>
                    <ResponsiveTable loadingResults={loadingResults}>
                        <thead><ReportHeaders header_names={report_table_headers} /></thead>
                        <tbody className={classes.table_data}>
                            {renderReports()}
                        </tbody>
                    </ResponsiveTable>
                    {Data && Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>}
                </div>
            </div>
        </div>
    )
}

export default OfferReport;
