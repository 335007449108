import classes from './NotFound.module.scss'
import CustomButton from '../../Customs/CustomButton/CustomButton'


const NotFound404 = () => {
    return(
        <div className={classes.container}>
            <div className={classes.main}>
                <span className={classes.title}>404</span>
                <span className={classes.subtitle}>Page Not Found.</span>
                
                <a className={classes.subtitle} href={'login'}>Click here to login instead.</a>
            </div>
        </div>
    )
}

export default NotFound404;