
import classes from './Main.module.scss'

import Dashboard from "../Dashboard/Dashboard"
import Offers from "../Offers/Offers"
import AdminOffers from "../Offers/AdminOffers"
import AdminTools from "../Admin/AdminTools"
import AdminUsers from "../Admin/AdminUsers"
import AdminConversions from "../Admin/AdminConversions"
import AdminApproval from "../Admin/AdminApproval"


import OfferReport from "../Reports/OfferReport"
import Billing from "../Billing/Billing.js"
import AdminBilling from "../Billing/AdminBilling.js"
import Reports from "../Reports/Reports"
import Tools from "../Tools/Tools"
import NavBar from "../NavBar/NavBar"
import TopBarMobile from "../TopBarMobile/TopBarMobile"

import { useEffect, useState } from "react"
import { useParams, useSearchParams } from 'react-router-dom'


import Tracking from "../Tools/Tracking"
import { useCookies } from 'react-cookie';
import { getAwaitingApprovals } from '../../utils/helper_functions.js'
import useWindowSize from '../../assets/hooks/useWindowSize.js'


import { uiActions } from '../..//store/ui-slice'
import { useSelector, useDispatch } from 'react-redux'




const Main = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const fetchAwaitingOffersNumber = useSelector(state => state.ui.fetchAwaitingOffersNumber)

    const page = params.page
    const offerId = params.offerId
    let {width} = useWindowSize()
    let [searchParams, setSearchParams] = useSearchParams()

    let return_link = searchParams.get("return")
    const [cookies, setCookie] = useCookies(['sidebarOpened'])

    // const [showSideBar, setShowSideBar] = useState(cookies?.sidebarOpened)
    const [showSideBar, setShowSideBar] = useState(true)
    




    useEffect(()=>{
        let width = window.innerWidth
        if(width < 500){
            setShowSideBar(false)
        }
    }, [])


    useEffect(()=>{
        const isAdmin = localStorage.getItem('role', false)
        
        if (isAdmin === 'ADMIN') {
            getAwaitingApprovals(dispatch, uiActions)
            setInterval(() => {
                getAwaitingApprovals(dispatch, uiActions)
            }, 15000)
        }
    }, [])
    


    const changeSideBar = (state=false) =>{
        setCookie('sidebarOpened', state)
        setShowSideBar(state)
    }

    return (
        <div className={classes.main_container}>
            {<NavBar changeSideBar={changeSideBar} showSideBar={showSideBar}/>}
            <div className={classes.main_page_div}>
                <TopBarMobile setShowSideBar={changeSideBar} showSideBar={showSideBar} isMobile={width < 500} />
                <div className={classes.main_page_div_except_topbarmobile}>
                    {page === 'home'  && <Dashboard />}
                    {page === 'dashboard'  && <Dashboard />}
                    {(page === 'offers'  && <Offers />)}
                    {(page === 'reports' && <Reports />)}
                    {(page === 'tracking' && <Tracking />)}
                    {(page === 'billing' && <Billing />)}
                    {(page === 'admin-billing' && <AdminBilling />)}
                    {(page === 'offer-report' && <OfferReport return_link={return_link} offerId={offerId} />)}
                    {(page === 'tools-tracking-code' && <Tracking />)}
                    {(page === 'profile' && <Tools />)}
                    {(page === 'admin-offers' && <AdminOffers />)}
                    {(page === 'admin-tools' && <AdminTools />)}
                    {(page === 'admin-users' && <AdminUsers />)}
                    {(page === 'admin-conversions' && <AdminConversions />)}
                    {(page === 'admin-approval' && <AdminApproval />)}
                    
                    {/* {(page === 'billing-payments' && <BillingAndPayments />)}
                    {(page === 'logs' && <Logs />)}
                    {(page === 'community' && <Community />)}
                    {(page === 'profile' && <Profile />)} */}
                </div>
            </div>
        </div>
    )
}
export default Main