import classes from './Login.module.scss'
import register_classes from '../Register/Register.module.scss'
import tools_classes from '../Tools/Tools.module.scss'

import email_icon from '../../assets/icons/email.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_green from '../../assets/icons/email_blue.svg'
import lock from '../../assets/icons/lock.svg'
// import lock_red from '../../assets/icons/lock_red.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_green from '../../assets/icons/lock_blue.svg'

import CustomInput1 from '../Customs/CustomInput1/CustomInput1'
import CustomButton from '../Customs/CustomButton/CustomButton'

import { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { uiActions } from '../..//store/ui-slice'

import { TimeOutHandler} from '../../utils/helper_functions'

import { ToastContainer, toast } from 'react-toastify';


const Login = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const [emailStatus, setEmailStatus] = useState('pending')
    const [passwordStatus, setPasswordStatus] = useState('pending')
    
    const [emailIcon, setEmailIcon] = useState(email_icon)
    const [passwordIcon, setPasswordIcon] = useState(lock)
    const [errorMsg, setErrorMsg] = useState("")

    const [emailNotVerfied, setEmailNotVerified] = useState(false)

    const [isError, setIsError] = useState(false)


    const dispatch = useDispatch()
    const navigate = useNavigate ()

    const API_URL = process.env.REACT_APP_API_URL

    useEffect(()=>{
        document.title = "LinkVantage | Login"
    }, [])

    const resendEmailVerification = async() => {
        const request_options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email
            })
        }
        props.setLoginProcess(true)
        try{
            let response = await fetch(`${API_URL}/api/v1/email-resend`, request_options)
            setErrorMsg("")
        }catch(ex){
            setErrorMsg("Faild to send email")
        }
        setEmailNotVerified(false)
        props.setLoginProcess(false)
}

    const submitHandler = async (e) => {
        e.preventDefault()
        setErrorMsg("")
        setEmailNotVerified(false)
        if(password.length < 6){
            setErrorMsg("Password min length 6")
            return;
        }
        if(passwordStatus === 'success' && emailStatus === 'success') {
            props.setLoginProcess(true)
            const request_options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    password: password,
                })
            }
            let response = null;
            try{
                response = await fetch(`${API_URL}/api/v1/login`, request_options)
            }catch(ex){
                setErrorMsg("Faild to fetch")
                props.setLoginProcess(false)
                return;
            }

            let res_code = response.status
            response = await response.json()
            // console.log("LOGIN : request_options ====>", res_code, response, response.role, response.access_token)


            if(res_code === 403) {
                setIsError(true)
                toast.error(response.message)
                props.setLoginProcess(false)
                return
            }
            // bad username/password
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                props.setLoginProcess(false)
                return;
            }

            if(!response.status){
                if(response.email===0){
                    setErrorMsg("Email Not verfied")
                    setEmailNotVerified(true)
                }else{
                    setErrorMsg(response.message)
                }
                props.setLoginProcess(false)
                return;
            }
            if(res_code === 200 && response.access_token && ['ADMIN','MODERATOR', 'CUSTOMER'].includes(response.role)) {
                localStorage.setItem('access_token', response.access_token)
                localStorage.setItem('role', response.role)
                localStorage.setItem('name', response.name)

                var balance = response.balance
                var last_payment_amount = response.last_payment_amount
                localStorage.setItem('balance', balance)
                localStorage.setItem('last_payment_amount', last_payment_amount)
                dispatch(userActions.setUserBalance(balance))
                dispatch(userActions.setLastPaymentAmount(last_payment_amount))

                dispatch(userActions.setUser(email))
                dispatch(userActions.setToken(response.access_token))
                dispatch(userActions.setIsAdmin(['ADMIN'].includes(response.role)))
                dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(response.role)))
                
                dispatch(uiActions.setIsLoggedIn(true))
                dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR'].includes(response.role)))
                navigate('/', {replace: true })
            }
            TimeOutHandler(() => props.setLoginProcess(false))
        }
    }



    const emailHandler = (e) => {
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_green)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }
            
        setEmail(e.target.value)
    }

    const passwordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setPasswordStatus('success')
            setPasswordIcon(lock_green)
        }
        else { 
            setPasswordStatus('pending') 
            setPasswordIcon(lock_blue)
        }
        setPassword(value)
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={tools_classes.error_msg}>{isError && <ToastContainer/>}</div> 

                <div className={classes.top_container}>
                    {/* <span className={classes.title}> {props.adminLogin ? "Admin Login":"Login"}</span> */}
                    <img src={require("../../assets/images/logo-dark.png")} alt='linkvantage.net' style={{width:"50%"}}/>
                </div>
                <span style={{color:"red", textAlign:"center", fontSize:"large"}}>
                    {errorMsg}
                </span>
                {emailNotVerfied &&
                
                <span style={{color:"red", textAlign:"center", fontSize:"large"}}>
                    <div style={{cursor:"pointer"}} onClick={resendEmailVerification}>Resend now</div>
                </span>
                }    
                <div className={classes.form_container}>
                    <form className={classes.form} onSubmit={submitHandler}>
                        <div className={classes.form_item}>
                            <label htmlFor='email'>Email</label>
                            <CustomInput1 image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                        </div>
                        <div className={classes.form_item}>
                            <label htmlFor='password'>Password</label>
                            <CustomInput1 image_src={passwordIcon} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={passwordStatus}/>
                        </div>
                        <div>
                            <Link style={{float:"right", fontSize:"0.8em"}} to="/forgot-password" >Forgot password?</Link>
                            <Link style={{float:"left", fontSize:"0.8em"}} to="/register" > Don't have account? Register here.</Link>
                        </div>
                        <div className={register_classes.register_btn_div}>
                            <CustomButton style={{float:"right"}} name='Login Now' color='blue' type='submit'/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login