import classes from './Reports.module.scss';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Button, Table, Tag } from 'rsuite';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import axios from 'axios'

import { IconButton } from 'rsuite';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import ReportHeaders from '../Reports/ReportHeaders'
import user_report_classes from '../Reports/Reports.module.scss'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import { DateRangePicker, Col } from 'rsuite'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import useWindowSize from '../../assets/hooks/useWindowSize.js'



const rowKey = '_id';
const { Column, HeaderCell, Cell } = Table;

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData)
        }}
        icon={<ExpandOutlineIcon />}
      />
    </Cell>
  )
  

const AdminConversions = () => {
    const API_URL = process.env.REACT_APP_API_URL
    let { width } = useWindowSize()

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)

  
    
    const [data, setData] = useState([])
    const [cookies, setCookie] = useCookies(['report_dates'])
    const [selectionRange, setSelectionRange] = useState([
        cookies.report_dates?new Date(cookies.report_dates[0]):new Date(new Date().setDate(new Date().getDate() - 7)),
        cookies.report_dates?new Date(cookies.report_dates[1]):new Date()
    ])


    let handleSelect = (dates) => {
        setCookie('report_dates', dates)
        setSelectionRange(dates)
        getConversions(dates)
    }
    

    const process_selection_dates = (dates, selectionRange) => {  
        var start_date, end_date

        if(dates){
            start_date = moment(dates[0]).format('YYYY-MM-DD')
            end_date =  moment(dates[1]).format('YYYY-MM-DD')
        }else{
            if(selectionRange){
                start_date = moment(selectionRange[0]).format('YYYY-MM-DD')
                end_date =  moment(selectionRange[1]).format('YYYY-MM-DD')

            }else{
                show_date_range_error()
                start_date = false
                end_date = false
            }
        }
        return [start_date, end_date]
    }  

    const show_date_range_error = () => {
        toast.error('Date is required.')
    }
    

    const getConversions = async (dates) => {
        setLoadingResults(true)
        var returned_selection_dates = process_selection_dates(dates, selectionRange)
        var start_date = returned_selection_dates[0]
        var end_date = returned_selection_dates[1]
        if(!start_date || !end_date){ 
            setLoadingResults(false)
            return 
        }

        let url = `${API_URL}/api/v1/admin-conversions?start_date=${start_date}&end_date=${end_date}`;

        let token = localStorage.getItem('access_token')
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.conversions){
                response.data.conversions.forEach(conv=>{
                    conv.key = conv['_id']
                })
                setData(response.data.conversions)
            }
            setLoadingResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
            }
        })
    }

    useEffect(() => {
        document.title = "LinkVantage | Admin Users";
        getConversions()
    },[])

    const pending_conversion = [
        "#",
        "Offer",
        "User",
        "Conversation",
        "Report Date",
        "Action"
    ];

    const updateConversion = (conversion, updatedStatus) => {
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/admin-conversions-update`;

        let token = localStorage.getItem('access_token')
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': 'Bearer '+token
            },
            data: {
                "conversion_id":conversion,
                "new_status":updatedStatus
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.status){
                getConversions()
            }
            setLoadingResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
            }
        })
    }

    const renderConversionsRequests = () =>{
        let prData = []
        data.forEach((conv, index) =>  {
            prData.push(
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{conv.offer_name}</td>
                    <td>{conv.user_obj.name}({conv.user_obj.email})</td>
                    <td>{conv.eventCount}</td>
                    <td>{conv.report_date}</td>
                    <td>
                        {
                            typeof(conv.accepted)==typeof(undefined)?
                            <>
                                <Button style={{background:"#ff000080", marginRight:"0.5em"}} onClick={()=>updateConversion(conv._id, false)}>Deny</Button>
                                <Button style={{background:"#00ff0080"}} onClick={()=>updateConversion(conv._id, true)}>Accept</Button>
                            </>
                            :
                            <>
                                {conv.accepted?"Accepted":"Denied"}
                            </>
                        }
                    </td>
                </tr>
            )
        })

        return prData
    }
    const predefinedRanges = [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          appearance: 'default'
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          appearance: 'default'
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          appearance: 'default'
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          appearance: 'default'
        },
        {
          label: 'All time',
          value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
          appearance: 'default'
        }
    ]
    

    return (
    <>  
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_middle}>
                    <div style={{marginBottom:"2em"}}>
                        <div className="heading">Conversions</div>
                        {!loadingResults && <div className={classes.data_picker_div}>
                                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign:"center"}}>
                                {width < 1030 ? (
                                    <DateRangePicker 
                                        showOneCalendar 
                                        appearance='default'
                                        ranges={predefinedRanges}
                                        value={selectionRange}
                                        onChange={handleSelect}
                                    />
                                ):(
                                    <DateRangePicker
                                        appearance='default'
                                        ranges={predefinedRanges}
                                        value={selectionRange}
                                        onChange={handleSelect}
                                    />
                                )}
                                </Col>
                            </div>
                            }
                            <p>Total Reports: {data.length}</p>

                    </div>
                    <ResponsiveTable loadingResults={loadingResults} design={{overflowX:"scroll"}}>
                        <thead><ReportHeaders header_names={pending_conversion}/></thead>
                        {(data.length===0)?(
                            <span className={classes.noData}>No Conversions</span>
                        ):(
                            <tbody className={user_report_classes.table_data}>{renderConversionsRequests()}</tbody>
                        )}
                    </ResponsiveTable>
                </div>                  
            </div>
        </div>
    </>
    )

}

export default AdminConversions;
