import classes from './billing.module.scss'
import "../Billing/AddNewPayment.css"
import report_classes from '../Admin/Reports.module.scss'
import user_report_classes from '../Reports/Reports.module.scss'


import info_icon from '../../assets/icons/info_icon.svg'
import delete_icon from '../../assets/icons/trash_red.svg'
import payment_cycle from '../../assets/icons/payment_cycle.svg'
import payment_request from '../../assets/icons/payment_request.svg'

import bank from '../../assets/images/bank.png'
import paypal from '../../assets/images/paypal.png'
import payoneer from '../../assets/images/payoneer.png'

import LoadingTable from '../LoadingTable/LoadingTable'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import BillingCard from "../Cards/BillingCard"

import { useState, useEffect } from 'react'
import { Col } from 'rsuite'

import { ImpulseSpinner } from "react-spinners-kit"  
import { ToastContainer, toast } from 'react-toastify'

import Tooltip from '@mui/material/Tooltip'
import axios from 'axios'
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math'

import { ReportHeaders } from '../../utils/helper_functions'

import htmlparser from 'html-react-parser'

import { userActions } from '../../store/user-slice'
import { useDispatch } from 'react-redux';



const Billing = () => {
    const dispatch = useDispatch()

    let access_token = localStorage.getItem('access_token')

    const monthly_income_table_headers = ["Month", "Ads<br>Revenue", "Conversion<br>Revenue", "Invalid Traffic<br>Deduction", "Revenue", "Status", "Action"]
    const pr_table_headers = ["Month", "Ads<br>Revenue", "Conversion<br>Revenue", "Invalid Traffic<br>Deduction", "Revenue", "Status", "Action"]
    const primary_payment_method_table_headers = ["Full Name", "Method", "Status", "Action"]
    const payment_method_table_headers = ["Full Name", "Method", "Status", "Created At", "Action"]
    const user_billing_table_headers = ["Month", "Amount", "Payment Method", "Payment Date", "Status", "Tracking ID"]


    const API_URL = process.env.REACT_APP_API_URL

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(true)
    const [popupLoading, setPopupLoading] = useState(false)

     // toast alert states
     const [isSuccess, setIsSuccess] = useState(false)                          
     const [isError, setIsError] = useState(false)

    // fragment section
    const [defaultFragment, setDefaultFragment] = useState('history')

    // payment cycle and payment request states
    // const [paymentCycleOpen, setPaymentCycleOpen] = useState(true)

    const [addPaymentModeModal, setAddPaymentModeModal] = useState(false)
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false)
    const [showPRPopup, setShowPRPopup] = useState(false)

    const [targetPaymentMethod, setTargetPaymentMethod] = useState()
    const [deletePaymentMethod, setDeletePaymentMethod] = useState(false)

    const [paymentMode, setPaymentMode] = useState("BANK")
    const [bankFieldsData, setBankFieldsData] = useState({
        "address" : "",
        "business_id" : "",
        "phone" : "",
        "bank_name" : "",
        "bank_address" : "",
        "account_number" : "",
        "swift_code" : "",
        "company_name" : "",
        "city" : "",
        "number" : "",
        "zip_code" : "",
        "country" : "",
        "province" : ""
    })

    const bankFieldsItems = [
        "address",
        "business_id",
        "phone",
        "bank_name",
        "bank_address",
        "account_number",
        "swift_code",
        "company_name",
        "city",
        "number",
        "zip_code",
        "country",
        "province"
    ]


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")


    // billing page data states
    const [userData, setUserData] = useState({})

    const [billingSummary, setBillingSummary] = useState({})
    const [monthlyIncomeData, setMonthlyIncomeData] = useState([])
    const [payrollAmount, setPayrollAmount] = useState(0.00)
    const [payrollIds, setPayrollIds] = useState([])

    const [paymentMethods, setPaymentMethods] = useState([])
    const [primaryPaymentMethods, setPrimaryPaymentMethods] = useState([])

    const [usersBilling, setUsersBilling] = useState([])

    // const generateDummyData = () => {
    //     const dummyData = [];
    //     for (let i = 0; i < 20; i++) {
    //         dummyData.push({
    //             period: 'April, 2024',
    //             invoice_id: 'J3kjDDh6n39adkjg',
    //             payment_method: '',
    //             payment_date: '1 May, 2024',
    //             transaction_amount: 43.56,
    //             status: 'Success',
    //         });
    //     }
    //     return dummyData;
    // }
    // const [usersBilling, setUsersBilling] = useState(generateDummyData());
    // const [monthlyIncomeData, setMonthlyIncomeData] = useState([
    //     {
    //         "month": "April, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "March, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "April, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "March, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "April, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "March, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "April, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "March, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "February, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "January, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "December, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "November, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "October, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    //     {
    //         "month": "September, 2024",
    //         "total_ad_revenue": 1.0,
    //         "total_conversion_revenue": 1,
    //         "total_revenue": 0.01,
    //         "payable": 0.01,
    //     },
    // ])
   


    const getBillings = async () => {
        let currentUrl = new URL(window.location.href)
        let user_id = currentUrl.searchParams.get('user_id')
        
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/user-billing`
        if(user_id){
            url += `?user_id=${user_id}`
        }
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+access_token }
        }

        axios.request(config)
        .then((response) => {
            setUserData(response.data.user_data) 

            setBillingSummary(response.data.billing_summary)
            setMonthlyIncomeData(response.data.months_data)
            setPaymentMethods(response.data.payment_methods)  
            setUsersBilling(response.data.all_billings)         
            setPayrollAmount(response.data.payroll_amount)        
            setPayrollIds(response.data.payroll_ids) 

            if(response.data.payment_methods.length > 0){
                // console.log("----------------->", response.data.payment_methods[0])
                setPrimaryPaymentMethods([response.data.payment_methods[0]])
            }

            setLoadingResults(false)
        })
        .catch((error) => {
            if(error && error.response && error.response.status > 400 ){
                setLoadingResults(false)
                // localStorage.removeItem('access_token')
                // localRedirect('/login')
            }
        })
    }

    useEffect(() => {
        document.title = "LinkVantage | Payment"
        getBillings()
    },[])


    useEffect(() => {
        if(userData && !loadingResults){
            var balance = userData.balance
            var last_payment_amount = userData.last_payment_amount

            localStorage.setItem('balance', balance)
            localStorage.setItem('last_payment_amount', last_payment_amount)
            
            dispatch(userActions.setUserBalance(balance))
            dispatch(userActions.setLastPaymentAmount(last_payment_amount))
        }
    },[userData])





    const renderMonthlyIncome = () =>{
        let reportsData = []
        monthlyIncomeData.forEach((data, index) =>  {
            reportsData.push(
                <tr key={index}>
                    <td className={classes.blue_text}>{data.month}</td>
                    <td>$ {round(data.ad_revenue+data.invalid_traffic_deduction, 2)?.toLocaleString()}</td>
                    <td>$ {round(data.conversion_revenue, 2)?.toLocaleString()}</td>
                    <td>$ {round(data.invalid_traffic_deduction, 2)?.toLocaleString()}</td>
                    <td>$ {round(data.total_revenue, 2)?.toLocaleString()}</td>


                    {/* status */}
                    {data.payment_status === 'PAID' && 
                        <Tooltip title={htmlparser(`Fund is send to your ${data.payment_method} account.`)} placement="top" arrow>
                            <td className={`${classes.green_text}`}>{data.payment_status}</td>
                        </Tooltip>
                    }
                    {data.payment_status === 'DENIED' && 
                        <Tooltip title={htmlparser(`Your payment request is denied.<br>Please contact us for more information.`)} placement="top" arrow>
                            <td className={`${classes.red_text}`}>{data.payment_status}</td>
                        </Tooltip>
                    }

                    {!data.payment_status && (
                        <>
                            {data.payment_requested?(
                                // <Tooltip title={htmlparser(`Wait patiently while we review your request.`)} placement="top" arrow></Tooltip>
                                <td className={`${classes.blue_text}`}>{htmlparser("Request Sent<br><small>(Under Review)</small>")}</td>
                            ):(
                                <>
                                    {data.can_send_pr ? (
                                        <Tooltip title={htmlparser(`Includes all "In Payroll" months`)} placement="top" arrow>
                                            <td className={`${classes.green_text}`}>{htmlparser(`Active Payroll<br><small className={classes.blue_text}>(Total $ ${round(payrollAmount, 2)})`)}</td>
                                        </Tooltip>
                                    ):(
                                        <>
                                            {data.payroll_status.includes('In Payroll')? (
                                                <td><small className={classes.blue_text}>{data.payroll_status}</small></td>
                                            ):(
                                                <Tooltip title={htmlparser(`You'd be able to send this payment <br> request from 1st to 5th ${data.payroll_status}`)} placement="top" arrow>
                                                    <td className={classes.orange_text}>{htmlparser(`Inactive Payroll<br><small>(Opens in ${data.payroll_status})</small>`)}</td>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {/* action */}
                    { primaryPaymentMethods.length === 1 &&
                        <>
                            {data.payment_status === 'PAID' && <td>-</td>}
                            {!data.payment_status && (
                                <>
                                    {(!data.payment_requested && data.can_send_pr)?
                                        (
                                            <td>
                                                {payrollAmount < 100 ? (
                                                    <Tooltip title="Minimum Amount is $100" placement="top" arrow><div className={classes.red_text}>Not Eligible</div></Tooltip>
                                                ):(
                                                    <button className={classes.default_green_btn} onClick={()=>{sendPaymentRequest()}}>Send Request</button>
                                                )}
                                            </td>
                                        ):(
                                            <td className={classes.blue_text}>-</td>
                                        )
                                    }
                                </>
                            )}
                            
                            {data.payment_status === 'DENIED' && <td><button className={classes.default_btn} onClick={()=>{handleContactSupport(data)}}>
                                Contact Support
                                </button></td>}
                        </>
                    }
                </tr>
            )
        })
        return reportsData
    }


    const sendPaymentRequest = () => {
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/user_pr`
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            headers: { 'Authorization': 'Bearer '+access_token, 'Content-Type': 'application/json' },
            data: JSON.stringify({"monthly_report_ids":payrollIds})
        }
        
        axios.request(config)
        .then((response) => {
            setMonthlyIncomeData(response.data.monthsData)  
            toast.success("Payment Request Sent Successfully.")
            setIsSuccess(true)
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
        }).finally(()=>{
            setLoadingResults(false)
        })
    }


    const handleContactSupport = () => {
        console.log("handle contact support clicked")
        window.open("mailto:support@linkvantage.net", "_blank")
    }


    const handlePaymentModal = () => {
        add_payment_mode_default_values()
        setAddPaymentModeModal(!addPaymentModeModal)
    }
    


    const onChangePaymentMode = (e) => {
        let value = e.target.value
        setPaymentMode(value)
    }



    const bankFieldChange = (e) => {
        let name  = e.target.name
        let value = e.target.value.trim()
        console.log("name and value :", name, value)

        bankFieldsData[name] = value
        setBankFieldsData(bankFieldsData)
    }



    // const renderBankFields = () => {
    //     let renders = []

    //     bankFieldsItems.forEach((name, index)=>{
    //         let nameLabel = name.replace("_", " ").charAt(0).toUpperCase() +  name.replace("_", " ").slice(1).toLocaleLowerCase()
    //         renders.push(
    //             <>
    //                 <div key={index} className={classes.form_child_div}>
    //                     <label>{nameLabel}</label>
    //                     <input defaultValue={bankFieldsData[firstName]}  name='' placeholder={nameLabel} onChange={bankFieldChange} />
    //                 </div>
    //             </>
    //         )
    //     })

    //     return renders
    // }



    const onTextFieldChange = (e) => {
        let name  = e.target.name
        let value = e.target.value.trim()
        if(name==="firstName"){
            setFirstName(value)
        }else if(name==="lastName"){
            setLastName(value)
        }else if(name==="email"){
            setEmail(value)
        }
    }



    const togglePaymentMode = (mode_id) => {
        setLoadingResults(true)
        let url = `${API_URL}/api/v1/toggle_payment_method`

        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            data: JSON.stringify({
                "mode_id": mode_id
            }),
            headers: { 
              'Authorization': 'Bearer '+access_token,
              'Content-Type': 'application/json'
            }
        }
        
        axios.request(config)
        .then((response) => {
            toast.success("New Primary Payment Method Added Successfully.")
            setIsSuccess(true)
        
            getBillings()
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                console.log('HERE :', error.response.data.message || "Error Occurred")
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
            setLoadingResults(false)
        })
    }



    const deletePaymentMode = (mode_id) => {
        setPopupLoading(true)
        let url = `${API_URL}/api/v1/delete_payment_method`

        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            data: JSON.stringify({
                "mode_id": mode_id
            }),
            headers: { 
              'Authorization': 'Bearer '+access_token,
              'Content-Type': 'application/json'
            }
        }
        
        axios.request(config)
        .then((response) => {
            setPopupLoading(false)
            toast.success("Payment method deleted successfully")
            setIsSuccess(true)
            
            setDeletePaymentMethod(false)
            setShowConfirmDeletePopup(false)
            getBillings()
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
            setPopupLoading(false)
        })
    }



    // const toast_default_values = () => {
    //     setIsSuccess(false)
    //     setIsError(false)
    // }

    const add_payment_mode_default_values = () => {
        setFirstName("")
        setLastName("") 
        setEmail("")
        setPaymentMode("BANK")
        setBankFieldsData({
            "address" : "",
            "business_id" : "",
            "phone" : "",
            "bank_name" : "",
            "bank_address" : "",
            "account_number" : "",
            "swift_code" : "",
            "company_name" : "",
            "city" : "",
            "number" : "",
            "zip_code" : "",
            "country" : "",
            "province" : ""
        })
    }
    const addPaymentMode = () => {
        let allowedToPass = true

        if(!firstName || !lastName || !email){
            toast.error("All fields Required")
            setIsError(true)
            return
        }

        let requestBody = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "mode": paymentMode
        }

        if(paymentMode === "BANK"){
            console.log("\n\nbankFieldsData :", bankFieldsData)

            for (const [index, fieldName] of bankFieldsItems.entries()) {
                if(!bankFieldsData[fieldName]){
                    let fieldNameLabel = fieldName.replace("_", " ").charAt(0).toUpperCase() +  fieldName.replace("_", " ").slice(1).toLocaleLowerCase()
                    toast.error(fieldNameLabel+" is Required")
                    setIsError(true)
                    allowedToPass = false
                    break
                }else{
                    requestBody[fieldName] = bankFieldsData[fieldName]
                }
            }
        }

        if(!allowedToPass){return}
        setPopupLoading(true)
        let url = `${API_URL}/api/v1/create_payment_method`
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: url,
            data: JSON.stringify(requestBody),
            headers: { 
              'Authorization': 'Bearer '+access_token,
              'Content-Type': 'application/json'
            }
        }
        axios.request(config)
        .then((response) => {
            toast.success("Payment method added successfully")
            setIsSuccess(true)
            setPopupLoading(false)

            add_payment_mode_default_values()
            setAddPaymentModeModal(false)
            getBillings()
        })
        .catch((error) => {
            var error_res_code = error.response.status
            if(error && (error_res_code === 401 || error_res_code === 403)){
                localStorage.removeItem('access_token')
                window.location.href = "/login"
            }else{
                toast.error(error.response.data.message || "Error Occurred")
                setIsError(true)
            }
            setPopupLoading(false)
        })
    }



    const renderUserBilling = () => {
        return (
            <>
            {usersBilling.map((item, index)=>{
                return (
                    <tr key={index}>
                        <td className={classes.blue_text}>{item.month}</td>
                        <td>$ {round(item.total_revenue, 2)?.toLocaleString()}</td>
                        <td>
                            {
                                item.method && 
                                <>
                                    {item.method.mode.toUpperCase() === 'PAYPAL' && <div className={classes.paypal}>{item.method.mode}</div>}
                                    {item.method.mode.toUpperCase() === 'PAYONEER' && <div className={classes.payoneer}>{item.method.mode}</div>}
                                    {item.method.mode.toUpperCase() === 'BANK' && <div className={classes.bank}>{item.method.mode}</div>}
                                </>
                            }
                        </td>
                        <td>{htmlparser(item.payment_date)}</td>
                        {item.payment_status === 'PAID' && <td className={`${classes.green_text}`}>{item.payment_status}</td>}
                        {item.payment_status === 'DENIED' && <td className={`${classes.red_text}`}>{item.payment_status}</td>}
                        <td>{item.tracking_id}</td>
                    </tr>
                )
            })}
            </>
        )
    }


    const renderPaymentMethods = () => {
        console.log('paymentMethods', paymentMethods)
        return (
            <>
            {paymentMethods.map((item, index)=>{
                return (
                    <tr key={index}>
                        <td className={report_classes.cursor_pointer} onClick={()=>{
                            setTargetPaymentMethod(item)
                            setDeletePaymentMethod(false)
                            setShowConfirmDeletePopup(true)
                        }}>{item.first_name}{item.last_name}</td>
                        <td>
                            {item.mode?.toUpperCase() === 'PAYPAL' && <div className={classes.paypal}>{item.mode}</div>}
                            {item.mode?.toUpperCase() === 'PAYONEER' && <div className={classes.payoneer}>{item.mode}</div>}
                            {item.mode?.toUpperCase() === 'BANK' && <div className={classes.bank}>{item.mode}</div>}
                        </td>
                        <td>
                            <div className={item.category === 'Primary'?classes.green_text:classes.orange_text}>{item.category}</div>
                        </td>
                        <td>{htmlparser(item.created_at)}</td>
                        <td>
                            <div className={classes.btn_div}>
                                <div className={classes.btn_div_right_align}>
                                    <Tooltip title="Details of Payment Method" placement="top" arrow>
                                        <img onClick={()=>{
                                            setTargetPaymentMethod(item)
                                            setDeletePaymentMethod(false)
                                            setShowConfirmDeletePopup(true)
                                        }} src={info_icon} alt="Details"/>
                                    </Tooltip>

                                    {item.category ==='Secondary' && <button className={classes.default_btn} onClick={()=>{togglePaymentMode(item._id)}}>Make Primary</button>}
                                   
                                   
                                    {item.category ==='Primary' && index === 0 && paymentMethods.length === 1 &&
                                     <Tooltip title="Delete" placement="top" arrow>
                                        <img onClick={()=>{
                                            setTargetPaymentMethod(item)
                                            setDeletePaymentMethod(true)
                                            setShowConfirmDeletePopup(true)
                                        }} src={delete_icon} alt="Delete"/>
                                    </Tooltip>}
                                    {item.category ==='Secondary' &&
                                     <Tooltip title="Delete" placement="top" arrow>
                                        <img onClick={()=>{
                                            setTargetPaymentMethod(item)
                                            setDeletePaymentMethod(true)
                                            setShowConfirmDeletePopup(true)
                                        }} src={delete_icon} alt="Delete"/>
                                    </Tooltip>}
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
            </>
        )
    }

    const renderPrimaryPaymentMethods = () => {
        return (
            <>
            {primaryPaymentMethods.map((item, index)=>{
                return (
                    <tr key={index}>
                        <td className={report_classes.cursor_pointer} onClick={()=>{
                            setTargetPaymentMethod(item)
                            setDeletePaymentMethod(false)
                            setShowConfirmDeletePopup(true)
                        }}>{item.first_name} {item.last_name}</td>
                        <td>
                            {item.mode?.toUpperCase() === 'PAYPAL' && <div className={classes.paypal}>{item.mode}</div>}
                            {item.mode?.toUpperCase() === 'PAYONEER' && <div className={classes.payoneer}>{item.mode}</div>}
                            {item.mode?.toUpperCase() === 'BANK' && <div className={classes.bank}>{item.mode}</div>}
                        </td>
                        <td>
                            <div className={item.category === 'Primary'?classes.green_text:classes.orange_text}>{item.category}</div>
                        </td>
                        <td>
                            <div className={classes.btn_div}>
                                <div className={classes.btn_div_right_align}>
                                    <Tooltip title="Details of Payment Method" placement="top" arrow>
                                        <img onClick={()=>{
                                            setTargetPaymentMethod(item)
                                            setDeletePaymentMethod(false)
                                            setShowConfirmDeletePopup(true)
                                        }} src={info_icon} alt="Details"/>
                                    </Tooltip>

                                    {item.category ==='Secondary' && <button className={classes.default_orange_btn} onClick={()=>{togglePaymentMode(item._id)}}>Make Primary</button>}
                                    
                                    {/* <Tooltip title="Delete" placement="top" arrow>
                                        <img onClick={()=>{
                                            setTargetPaymentMethod(item)
                                            setDeletePaymentMethod(true)
                                            setShowConfirmDeletePopup(true)
                                        }} src={delete_icon} alt="Delete"/>
                                    </Tooltip> */}
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })}
            </>
        )
    }


    const renderPaymentMethodIcons = (paymentMode) => {
        return (
            <>
                {paymentMode === "BANK" && <img className={classes.payment_method_logo} src={bank} alt='Bank'/>}
                {paymentMode === "PAYPAL" && <img src={paypal} alt='Paypal'/>}
                {paymentMode === "PAYONEER" && <img src={payoneer} alt='Payoneer'/>}
            </>
        )
    }

    

    // ############################# RENDERING SECTION #############################
    return (
        <div className={classes.container}>
            <div className={classes.main}>
            {loadingResults ? (
                <LoadingTable/>
            ):(
                <>
                {(isSuccess || isError) && <ToastContainer/>}

                {showConfirmDeletePopup && (
                    <PopUpCard toggle={()=>{setShowConfirmDeletePopup(false)}} header={deletePaymentMethod?'Delete Payment Method':'Details of Payment Method'}>
                        <div className={classes.popup_content_div}>
                            <div className={classes.popup_content_div_top}>
                                {deletePaymentMethod && 
                                    <div className={classes.popup_content_main}>
                                        Once you click <b>Delete,</b> Following payment method will be deleted forever.
                                    </div>
                                }
                                <div className={classes.form_info_div}>
                                    <div className={classes.form_info_div_each}>
                                    <div className={classes.label_50}>Method :</div>
                                    {targetPaymentMethod.mode === 'PAYPAL' && <div className={classes.paypal}>{targetPaymentMethod.mode}</div>}
                                        {targetPaymentMethod.mode === 'PAYONEER' && <div className={classes.payoneer}>{targetPaymentMethod.mode}</div>}
                                        {targetPaymentMethod.mode === 'BANK' && <div className={classes.bank}>{targetPaymentMethod.mode}</div>}
                                    </div>
                                    <div className={classes.form_info_div_each}>
                                        <div className={classes.label_50}>Status :</div>
                                        <div className={targetPaymentMethod.category === 'Primary'?classes.green_text:classes.orange_text}>{targetPaymentMethod.category}</div>
                                    </div>
                                </div>

                                <div className={classes.form_info_div}>
                                    <div className={classes.form_info_div_each}>
                                        <div className={classes.label_50}>Full Name :</div>
                                        <div>{targetPaymentMethod.first_name} {targetPaymentMethod.last_name}</div>
                                    </div>
                                    <div className={classes.form_info_div_each}>
                                        <div className={classes.label_50}>Email :</div>
                                        <div>{targetPaymentMethod.email}</div>
                                    </div>
                                </div>

                                {targetPaymentMethod.mode === 'BANK' &&
                                <>
                                    <div className={classes.form_info_div}>
                                        <div className={classes.form_info_div_each}>
                                            <div className={classes.label_50}>Bank Name :</div>
                                            <div>{targetPaymentMethod.bank_name}</div>
                                        </div>
                                        <div className={classes.form_info_div_each}>
                                            <div className={classes.label_50}>Swift Code :</div>
                                            <div>{targetPaymentMethod.swift_code}</div>
                                        </div>
                                    </div>
                                    <div className={classes.form_info_div}>
                                        <div className={classes.form_info_div_each}>
                                            <div className={classes.label_50}>Account Number :</div>
                                            <div>{targetPaymentMethod.account_number}</div>
                                        </div>
                                        <div className={classes.form_info_div_each}>
                                            <div className={classes.label_50}>Bank Address :</div>
                                            <div>{targetPaymentMethod.bank_address}</div>
                                        </div>
                                    </div>
                                </>}                                
                                <div className={classes.form_info_div}>
                                    <div className={classes.form_info_div_each}>
                                        <div className={classes.label_50}>Active Status :</div>
                                        <div className={targetPaymentMethod.is_active === true? classes.green_text: classes.red_text}>{targetPaymentMethod.is_active === true?'Active':'Inactive'}</div>
                                    </div>
                                    <div className={classes.form_info_div_each}>
                                        <div className={classes.label_50}>Created At :</div>
                                        <div>{htmlparser(targetPaymentMethod.created_at.replace("<br>", " at "))}</div>
                                    </div>
                                </div>
                            </div>

                            {deletePaymentMethod && 
                            <div className={classes.delete_payment_method_btn_div}>
                                {popupLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                                <button onClick={()=>{
                                    setDeletePaymentMethod(false)
                                    setShowConfirmDeletePopup(false)
                                }} className={report_classes.cancel_btn}>Cancel</button>
                                <button onClick={()=>{deletePaymentMode(targetPaymentMethod._id)}} className={report_classes.apply_btn}>Delete</button> 
                            </div>}
                        </div>   
                        
                    </PopUpCard>
                )}

                {addPaymentModeModal &&
                    <PopUpCard bigPopup={true} header="Add Payment Method" toggle={handlePaymentModal}>
                        <div className={classes.form_div}>
                            <div className={classes.form_div_top}>
                                <div className={paymentMode==="BANK"?classes.form_select_div:classes.form_child_div}>
                                    <div>
                                        <label>Payment Mode</label>
                                        <select name="paymentMode" required="" value={paymentMode} onChange={onChangePaymentMode} >
                                            <option selected={(paymentMode==="BANK")?"Selected":""} value={"BANK"}>Bank</option>
                                            <option selected={(paymentMode==="PAYPAL")?"Selected":""} value={"PAYPAL"}>Paypal</option>
                                            <option selected={(paymentMode==="PAYONEER")?"Selected":""} value={"PAYONEER"}>Payoneer</option>
                                        </select>
                                    </div>

                                    {(paymentMode !== "BANK") && (
                                        <div>
                                            <label>Email</label>
                                            <input value={email} onChange={onTextFieldChange} name="email" required="" placeholder="Email" />
                                        </div>
                                    )}
                                </div>

                                <div className={classes.form_child_div}>
                                    <div>
                                        <label>First Name</label>
                                        <input value={firstName} onChange={onTextFieldChange} name="firstName" required="" placeholder="First Name" />
                                    </div>
                                    <div>
                                        <label>Last Name</label>
                                        <input value={lastName} onChange={onTextFieldChange} name="lastName" required="" placeholder="Last Name" />
                                    </div>
                                </div>

                                {(paymentMode === "BANK") && (
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Email</label>
                                            <input value={email} onChange={onTextFieldChange} name="email" required="" placeholder="Email" />
                                        </div>
                                        <div>
                                            <label>Business ID</label>
                                            <input defaultValue={bankFieldsData['business_id']}  name='business_id' placeholder="Business ID" onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                )}

                                {(paymentMode === "BANK") &&
                                <>
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Address</label>
                                            <input defaultValue={bankFieldsData['address']}  name='address' placeholder='Address' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>Phone</label>
                                            <input defaultValue={bankFieldsData['phone']}  name='phone' placeholder='Phone' onChange={bankFieldChange} />
                                        </div>
                                    </div>

                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Bank Name</label>
                                            <input defaultValue={bankFieldsData['bank_name']}  name='bank_name' placeholder='Bank Name' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>Bank Address</label>
                                            <input defaultValue={bankFieldsData['bank_address']}  name='bank_address' placeholder='Bank Address' onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Account Number</label>
                                            <input defaultValue={bankFieldsData['account_number']}  name='account_number' placeholder='Account Number' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>Swift Code</label>
                                            <input defaultValue={bankFieldsData['swift_code']}  name='swift_code' placeholder='Swift Code' onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Company Name</label>
                                            <input defaultValue={bankFieldsData['company_name']}  name='company_name' placeholder='Company Name' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>City</label>
                                            <input defaultValue={bankFieldsData['city']}  name='city' placeholder='City' onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Number</label>
                                            <input defaultValue={bankFieldsData['number']}  name='number' placeholder='Number' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>Zip Code</label>
                                            <input defaultValue={bankFieldsData['zip_code']}  name='zip_code' placeholder='Zip Code' onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                    <div className={classes.form_child_div}>
                                        <div>
                                            <label>Country</label>
                                            <input defaultValue={bankFieldsData['country']}  name='country' placeholder='Country' onChange={bankFieldChange} />
                                        </div>
                                        <div>
                                            <label>Province</label>
                                            <input defaultValue={bankFieldsData['province']}  name='province' placeholder='Province' onChange={bankFieldChange} />
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className={(paymentMode === "BANK")?classes.btn_end_div:classes.btn_end_div_absolute}>
                                {popupLoading && <ImpulseSpinner size={30} frontColor="#02cd19" backColor="#fff"/>}
                                {renderPaymentMethodIcons(paymentMode)}
                                <button className={classes.default_green_btn} onClick={addPaymentMode}>Add Payment Method</button>
                            </div>
                        </div>
                    </PopUpCard>
                }

                {showPRPopup &&
                    <PopUpCard bigPopup={true} header="Send Payment Requests" toggle={()=>{setShowPRPopup(false)}}>
                        <div className={classes.form_div}>
                            <div className={classes.form_div_top}>
                                <div className={classes.form_child_div}>
                                    <ResponsiveTable loadingResults={loadingResults}>
                                        <thead><ReportHeaders header_names={pr_table_headers}/></thead>
                                        {(monthlyIncomeData.length===0)? (
                                            <span className={classes.noDataBilling}>No Earning By Month Data</span>
                                        ):(
                                            <tbody className={user_report_classes.table_data}>{renderMonthlyIncome()}</tbody>
                                        )}
                                    </ResponsiveTable>
                                </div>
                            </div>
                        </div>
                    </PopUpCard>
                }

                                
                            
                        

                <div className={classes.main_top}>
                    <div className={classes.main_title}>
                        <div className={classes.heading}>Payments</div>

                        {/* {userData.is_admin && <NavLink className={classes.default_btn} to="/admin-billing">Admin Payment</NavLink>} */}
                    </div>
                    <div className={classes.main_operation}>
                        <div className={classes.customButton}>
                            {/* <div>Balance : ${parseFloat(userData.balance).toFixed(2)}</div> */}
                            {/* <div>Balance : ${userData.balance}</div> */}
                            {/* <CustomButton disabled={userData.payment_requested || userData.balance < minimumBalance } color={ (userData.payment_requested)?"red":(userData.balance >= minimumBalance)?"green":"red" } name={(userData.payment_requested)?"Payment Requested":(userData.balance >= minimumBalance)?"Request Payment":"Minimum balance $100" } onClick={sendPaymentRequest}/> */}
                        </div>
                    </div>
                </div>

                <div className={classes.main_middle}>
                    <div className={classes.switch_fragment_div_parent}>
                        <div className={classes.switch_fragment_div}>
                            <div id='history' className={defaultFragment==="history"?classes.active_fragment:classes.inactive_fragment} onClick={(e) => setDefaultFragment(e.target.id)}>Overview</div>
                            <div id='setting' className={defaultFragment==="setting"?classes.active_fragment:classes.inactive_fragment} onClick={(e) => setDefaultFragment(e.target.id)}>Settings</div>
                        </div>
                    </div>

                    {defaultFragment === "history" ?(
                        <>
                            {/* Payment Methods */}
                            <div className={classes.main_middle_top}>
                                <Col xl={6} md={8} sm={12} xs={24}>
                                    <BillingCard title="Your Earnings So Far" value={`USD $ ${billingSummary.total_earned?.toLocaleString()??"0.00"}`} tooltip="Total Revenue From All Time"/>
                                </Col>
                                <Col xl={6} md={8} sm={12} xs={24}>
                                    <BillingCard title='Balance' value={`USD $ ${billingSummary.balance?.toLocaleString()??"0.00"}`} tooltip="Your Earning So Far - Paid Amounts"/>
                                </Col>
                                <Col xl={6} md={8} sm={12} xs={24}>
                                    <BillingCard title='Last Payment Issued' value={htmlparser(billingSummary.last_payment_issued?.replace("<br>", " at ")??"")} tooltip="Last time when you received funds"/>
                                </Col>
                                <Col xl={6} md={8} sm={12} xs={24}>
                                    <BillingCard 
                                        title='Next Payment Cycle' 
                                        value={billingSummary.next_payment_cycle} 
                                        tooltip={billingSummary.can_send_pr?"Now you can send payment request to get paid":"Wait until next payment cycle to send payment requests"}
                                        image = {billingSummary.can_send_pr?payment_request:payment_cycle}
                                        onClick={billingSummary.can_send_pr? () => setShowPRPopup(true): ()=> {}}
                                    />
                                </Col>
                            </div>

                            <div className={classes.main_middle_bottom}>
                                <div className={classes.sectionContainer_row}>
                                    {/* Earning by month */}                                    
                                    <div className={classes.sectionContainer}>
                                        <div className={classes.header}>Earning By Month</div>
                                        <div className={(monthlyIncomeData.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                            <ResponsiveTable loadingResults={loadingResults}>
                                                <thead><ReportHeaders header_names={monthly_income_table_headers}/></thead>
                                                {(monthlyIncomeData.length===0)? (
                                                    <span className={classes.noDataBilling}>No Earning By Month Data</span>
                                                ):(
                                                    <tbody className={user_report_classes.table_data}>{renderMonthlyIncome()}</tbody>
                                                )}
                                            </ResponsiveTable>
                                        </div>
                                    </div>
                                    <div className={classes.sectionContainer}>
                                        <div className={classes.header}>Settings</div>
                                        <div className={(paymentMethods.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                            <ResponsiveTable loadingResults={loadingResults}>
                                                <thead><ReportHeaders header_names={primary_payment_method_table_headers}/></thead>
                                                {(paymentMethods.length===0)? (
                                                    <span className={classes.noDataBilling}>No Existing Payment Methods</span>
                                                ):(
                                                    <>
                                                    <tbody className={user_report_classes.table_data}>{renderPrimaryPaymentMethods()}</tbody>
                                                    <button id='setting' className={classes.table_btn} onClick={(e) => setDefaultFragment(e.target.id)}>Change Payment Setting</button>
                                                    </>
                                                )}
                                            </ResponsiveTable>
                                        </div>
                                    </div>
                                </div>


                                {/* all billings */}
                                <div className={classes.sectionContainer_row}>
                                    <div className={classes.sectionContainer}>
                                        <div className={classes.header}>All Billings</div>
                                        <div className={(usersBilling.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                            <ResponsiveTable loadingResults={loadingResults}>
                                                <thead><ReportHeaders header_names={user_billing_table_headers}/></thead>
                                                {(usersBilling.length===0)? (
                                                    <div className={classes.noDataBilling}>No Monthly Income Data</div>
                                                ):(
                                                    <tbody className={user_report_classes.table_data}>{renderUserBilling()}</tbody>
                                                )}
                                            </ResponsiveTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ):(
                        <div className={classes.sectionContainer}>
                            <div className={classes.header_extra_top}>
                                <div>Settings</div>
                                <button className={classes.default_green_btn} onClick={()=> setAddPaymentModeModal(true)}>Add Payment Method</button>
                            </div>

                            <div className={(paymentMethods.length===0)?classes.local_table_div_billing:user_report_classes.table_div}>
                                <ResponsiveTable loadingResults={loadingResults}>
                                    <thead><ReportHeaders header_names={payment_method_table_headers}/></thead>
                                    {(paymentMethods.length===0)? (
                                        <span className={classes.noDataBilling}>No Existing Payment Methods</span>
                                    ):(
                                        <tbody className={user_report_classes.table_data}>{renderPaymentMethods()}</tbody>
                                    )}
                                </ResponsiveTable>
                            </div>
                        </div>
                    )}
                </div>
                </>
            )}
            </div>
        </div>
    )
}

export default Billing