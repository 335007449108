import classes from "./Tools.module.scss"

// packages
import { useState, useEffect } from "react"

import React from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// components
import { useDispatch } from 'react-redux'

import { userActions } from '../../store/user-slice'

// icons 
import red_lock from '../../assets/icons/lock_red.svg'
import blue_lock from '../../assets/icons/lock_blue.svg'
import LoadingTable from '../LoadingTable/LoadingTable'

import { apiHandler } from '../../utils/helper_functions'


const Tools = () => {
    const dispatch = useDispatch()

    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassConf, setNewPassConf] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const minLength = 7;
    
    const [profileName, setProfileName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [telegram, setTelegram] = useState("")
    const [skype, setSkype] = useState("")


    const [taxId, setTaxId] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setCountryState] = useState("")
    const [country, setCountry] = useState("")
    const [zip, setZip] = useState("")



    const undefined_to_empty_str = (value) => {
        if (value === undefined){
            return ""
        }else{
            return value
        }
    }

    // ####################### functions section #######################
    const getProfileData = async ()=>{
        setIsLoading(true)

        const response = await apiHandler('/get_profile_data', 'GET', null, setError)
        if(response){
            console.log("get profile data :", response.data)
            setProfileName(response.data.name)
            setEmail(response.data.email)

            setPhone(undefined_to_empty_str(response.data.phone))
            setTelegram(undefined_to_empty_str(response.data.telegram))
            setSkype(undefined_to_empty_str(response.data.skype))
            
            setTaxId(undefined_to_empty_str(response.data.taxId))
            setAddress(undefined_to_empty_str(response.data.address))
            setAddress2(undefined_to_empty_str(response.data.address2))
            setCity(undefined_to_empty_str(response.data.city))
            setCountryState(undefined_to_empty_str(response.data.state))
            setCountry(undefined_to_empty_str(response.data.country))
            setZip(undefined_to_empty_str(response.data.zip))

            console.log("data got :", response.data.phone,  response.data.telegram,  response.data.skype,  response.data.address)

        }
        setIsLoading(false)
    }

    useEffect(()=>{
        document.title = "LinkVantage | Profile";
        getProfileData()
    }, [])












    // change password functionality
    const submitHandler = async (e)=>{
        e.preventDefault()
        setSuccess(false)
        setError(false)
        setIsLoading(true)

        let minLength = 7;
        if(newPass.length <= minLength || newPassConf.length <= minLength){
            setError(true)
            setIsLoading(false)
            toast.error("Minimum password length 8")
            return
        }
        if(newPass !== newPassConf){
            setError(true)
            setIsLoading(false)
            toast.error("Password not matching")
            return
        }

        console.log("Data for update password is :", currentPass, newPass, newPassConf)
        const response = await apiHandler('/change-password', 'POST', {
            currentPass: currentPass,
            newPass: newPass,
            newPassConf: newPassConf
        }, setError)

        if(response){
            setCurrentPass('')
            setNewPass('')
            setNewPassConf('')
            setSuccess(true)
            toast.success('Password changed successfully.')
        }
        setIsLoading(false)
    }



    const passwordHandler = (e) => {
        setSuccess(false)
        let name = e.target.name;
        let val = e.target.value;
        if(name === "current-password"){
            setCurrentPass(val)
        }else if(name === "new-password"){
            setNewPass(val)
        }else if(name === "confirm-password"){
            setNewPassConf(val)
        }
    }










    // update profile data
    const profileSubmitHandler = async(e) => {
        e.preventDefault()
        setSuccess(false)
        setError(false)

        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        if(email.length === 0 || !(EMAIL_REGEX.test(email))){
            setError(true)
            toast.error("Invalid email")
            return
        }

        console.log("Tools req optins :", phone, telegram, skype, address)
        setIsLoading(true)
        const response = await apiHandler('/update-profile', 'POST', {
            user:false,

            name: profileName,
            email: email,
            phone: phone,
            telegram: telegram,
            skype: skype,

            taxId: taxId,
            address: address,
            address2: address2,
            city: city,
            state: state,
            country: country,
            zip: zip
        }, setError)

        if(response){
            console.log("res is :", response.data)
            if(profileName !== ""){
                localStorage.setItem('name', profileName)
                dispatch(userActions.setUserName(profileName))
            }
            setSuccess(true)
            toast.success("Profile Updated")
        }
        setIsLoading(false)
    }





    return (
        <div className={classes.container}>
            <div className={classes.main_center}>
                <div className={classes.main_top}>
                    <div className={classes.breadcrumb_title}>
                       <div className="heading">Profile Update</div>
                    </div>   
                    <div className={classes.success_msg}>{success && <ToastContainer/>}</div> 
                    <div className={classes.error_msg}>{error && <ToastContainer/>}</div> 
                </div>
               
                <div className={classes.main_bottom}>
                    {isLoading? (<LoadingTable />):(
                    <div className={classes.main_bottom_100}>
                        <div className={classes.form_container_center}>                                             
                            <div className={classes.section_contact_information}>
                                <div className={classes.contact_information}>
                                    <div className={classes.contact_information_title}>
                                        <h6>Contact Information</h6>
                                    </div>
                                    <div className={classes.line_password}></div>
                                    
                                        {/*<p>Manage your contact information. All information here will be reflected in your receipts.</p>*/}
                                    <div className={classes.contact_information_text}></div>

                                    <form onSubmit={profileSubmitHandler}>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Full Name</div>
                                                <input type="text" onChange={(e)=>setProfileName(e.target.value)} value={profileName} id="fullName" name='name' placeholder="Full Name" autocomplete="off"/>
                                        </div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Email</div>
                                                <input type="text"  onChange={(e)=>setEmail(e.target.value)} value={email} id="email" name='email' placeholder="Email" autocomplete="off"/>
                                        </div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Phone</div>
                                                <input type="text"   onChange={(e)=>setPhone(e.target.value)} value={phone} id="phone" name='phone' placeholder="Phone" autocomplete="off"/>
                                        </div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Telegram</div>
                                                <input type="text"   onChange={(e)=>setTelegram(e.target.value)} value={telegram} id="telegram" name='telegram' placeholder="Telegram" autocomplete="off"/>
                                        </div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Skype</div>
                                                <input type="text"   onChange={(e)=>setSkype(e.target.value)} value={skype} id="skype" name='skype' placeholder="Skype" autocomplete="off"/>
                                        </div>

                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Tax ID</div>
                                                <input name='name' type='text' onChange={(e)=>setTaxId(e.target.value)} value={taxId}  id="taxId" placeholder="Tax ID" autocomplete="off"/>
                                        </div>

                                        <div className={classes.btn_submit_div}>
                                            <div className={classes.btn_submit}>
                                                <button type='submit'>Save</button>
                                            </div> 
                                        </div> 




                                        <div className={`${classes.contact_information_title} ${classes.top_extra_margin}`}>
                                            <h6>Company Information</h6>
                                        </div>
                                        <div className={classes.line_password}></div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Address</div>
                                                <input  name='name' type='text' onChange={(e)=>setAddress(e.target.value)} value={address}  id="address" placeholder="Address" autocomplete="off"/>
                                        </div>

                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>Address Line 2</div>
                                                <input  name='name' type='text' onChange={(e)=>setAddress2(e.target.value)} value={address2}  id="address2" placeholder="Address Line 2" autocomplete="off"/>
                                        </div>


                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>City</div>
                                                <input  name='name' type='text' onChange={(e)=>setCity(e.target.value)} value={city}  id="city" placeholder="City" autocomplete="off"/>
                                        </div>
                                        <div className={classes.form_group}>
                                                <div className={classes.text_left}>State</div>
                                                <input  name='name' type='text' onChange={(e)=>setCountryState(e.target.value)} value={state} id="state" placeholder="State" autocomplete="off"/>
                                         </div>
                                        <div className={classes.form_group}>
                                            <div className={classes.text_left}>Country</div>
                                                <select className={`${classes.form_select}`} id="country"  name='name' type='text' onChange={(e)=>setCountry(e.target.value)} value={country}>
                                                    <option value="">--Select Country--</option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Aland Islands">Aland Islands</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antarctica">Antarctica</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Curacao">Curacao</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guernsey">Guernsey</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jersey">Jersey</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                                    <option value="Kosovo">Kosovo</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macao">Macao</option>
                                                    <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russian Federation">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Barthelemy">Saint Barthelemy</option>
                                                    <option value="Saint Helena">Saint Helena</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Martin">Saint Martin</option>
                                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Sint Maarten">Sint Maarten</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                                    <option value="South Sudan">South Sudan</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Timor-Leste">Timor-Leste</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Viet Nam">Viet Nam</option>
                                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                    <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                        </div>
                                        <div className={classes.form_group}>
                                            <div className={classes.text_left}>Zip</div>
                                            <input name='name' type='text' onChange={(e)=>setZip(e.target.value)} value={zip}  id="zip" placeholder="Zip" autocomplete="off"/>
                                        </div>

                                        {/* <div className={classes.form_rows}> 
                                            <div className={classes.form_city}>
                                                
                                            </div> 
                                        </div> */}
                                        {/* <div>
                                            <CustomButton style={{float:"right"}} name='Update Profile' color='blue' type='submit'/>
                                        </div> */}
                                        {/* <div className={classes.line_password}></div> */}

                                        <div className={classes.btn_submit_div}>
                                            <div className={classes.btn_submit}>
                                                <button type='submit'>Save</button>
                                            </div> 
                                        </div> 
                                    </form>   



                                    {/* change password functionality */}
                                    <div className={`${classes.contact_information_title} ${classes.top_extra_margin}`}>
                                        <h6>Change Password</h6>
                                    </div>
                                    <div className={classes.line_password}></div>
                                    {isLoading?(<LoadingTable />):(
                                    <form onSubmit={submitHandler}>
                                        <div className={`${classes.form_group}`}>
                                            <div className={classes.text_left}>Current Password</div>
                                            <input image_src={(currentPass.length>minLength)?blue_lock:red_lock} placeholder='************' name='current-password' type='password' onChange={passwordHandler} value={currentPass} required status={(currentPass.length>minLength)}/>
                                        </div>
                                        <div className={classes.form_group}>
                                            <div className={classes.text_left}>New Password</div>
                                            <input  image_src={(newPass.length>minLength)?blue_lock:red_lock} placeholder='************' name='new-password' type='password' onChange={passwordHandler} value={newPass} required status={(newPass.length>minLength)}/>  
                                        </div>
                                        <div className={classes.form_group}>
                                            <div className={classes.text_left}>Confirm Password</div> 
                                            <input image_src={(newPassConf.length>minLength)?blue_lock:red_lock} placeholder='************' name='confirm-password' type='password' onChange={passwordHandler} value={newPassConf} required status={(newPassConf.length>minLength)}/>     
                                        </div>

                                        <div className={classes.btn_submit_div}>
                                            <div className={classes.btn_submit}>
                                                <button type="submit">Change Now</button>
                                            </div> 
                                        </div>                                         
                                    </form>                                                                            
                                    )}
                                </div>    
                            </div> 
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Tools;