import classes from './IconContainerLarge.module.scss'

const IconContainerLarge = (props) => {

    let styles = [classes.container,classes.blue].join(' ')

    if(props.color) {
        if(props.color === 'green') styles = [classes.container,classes.green].join(' ')
        if(props.color === 'red') styles = [classes.container,classes.red].join(' ')
    }

    return (
        <div className={styles}>
            <img src={props.image_src} style={props.imageStyle} alt={props.image_alt || 'please add alt'}/>
        </div>
    )
}

export default IconContainerLarge;