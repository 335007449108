import { createSlice } from '@reduxjs/toolkit'
import names from '../utils/names_langs'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        language: {values:names.ENG, name:'english', code:'ENG'},
        isLoggedIn: true, 
        navigateToCustomers: false,
        showAdminInterface: false,
        fetchAwaitingOffersNumber: false,
        
    },
    reducers: {
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload
        },
        setNavigateToCustomers(state, action) {
            state.navigateToCustomers = action.payload
        },
        setShowAdminInterface(state, action) {
            state.showAdminInterface = action.payload
        },
        setTargetUserName(state, action) {
            state.targetUserName = action.payload
        },
        setAwaitingOffersNumber(state, action) {
            state.awaitingOffersNumber = action.payload
        },
        setFetchAwaitingOffersNumber(state, action) {
            state.fetchAwaitingOffersNumber = action.payload
        }
    }
})

export const uiActions = uiSlice.actions
export default uiSlice