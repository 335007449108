
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
            token: null,
            isAdmin: false,
            isModerator: false,
            lang: 'ENG',
            email: '',
            username: '',
            balance: 0.00,
            last_payment_amount: 0.00
    },
    reducers: {
        setUserName(state, action) {
            state.username = action.payload
        },
        setUserBalance(state, action) {
            state.balance = action.payload
        },
        setLastPaymentAmount(state, action) {
            state.last_payment_amount = action.payload
        },
        setUser(state, action) {
            state.email = action.payload
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload
        },
        setIsModerator(state, action) {
            state.isModerator = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        }
    }
})

export const userActions = userSlice.actions
export default userSlice