import classes from './ResponsiveTable.module.scss'

import LoadingTable from '../../LoadingTable/LoadingTable'

import useWindowSize from '../../../assets/hooks/useWindowSize.js'




const ResponsiveTable = (props) => {
    let loadingResults = props.loadingResults
    let {width} = useWindowSize()

    return (
        <div className={classes.container} style={(width < 1200 && width > 500) ? {width: "100%", ...props.design} : {...props.design}}>
            {loadingResults && <LoadingTable />}
            {!loadingResults && <table className={classes.table} cellSpacing={0}>{props.children}</table>}
        </div>
    )

}

export default ResponsiveTable;