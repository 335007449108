const NAMES = {
    ENG: {
        sideBarNames: {
            dashboard: "Dashboard",
            offers: "Offers",
            reports: "Reports",
            tools: "Tools",
            billings: "Billing & Payments",
            logs: "Logs",
            community: "Community",
        },

        btn_texts: {
            clear: "Clear",
            apply: "Apply",
            search: "Search",
            save: "Save",
            edit: "Edit",
            cancel: "Cancel",
            filter: "Filter",
            change_payment_setting: "Change Payment Setting",

        },

        filter_texts: {
            filters: "Filters",
            country: "Country",
            price_format: "Price Format",
            device: "Device",

            filter_countries: [
                "Israel",
                "Bangladesh",
                "USA",
                "Japan"
            ],
            filter_prices: [
                2.0,
                22.1,
                26.6,
                5.6
            ], 
            filter_devices: [
                "Android",
                "IOS",
                "Windows",
                "Mac OS",
                "Linux"
            ]
        },

        common_texts: {
            date: "Date",
        },

        pagination: {
            showing: "Showing",
            rows: "items",
            page: "Page",
            of: "of",
            out_of: "out of",

        },


        date_options: {
            last_week: "7 days",
            last_two_week: "14 days",
            last_month: "1 month",
            last_three_month: "3 month",
            last_six_month: "6 month",
            last_year: "1 year",
        },


        // page specific content
        dashboardContent: {
            title: "Dashboard",
            overview: "Overview",
            apply: "Apply",

            insight_card_texts: {
                clicks: "Clicks",
                conversions: "Conversions",
                revenue: "Revenue",
                avg_epc: "Avg. EPC",

            },

            graphs:{
                combined_graph:"Combined Graph",
                clicks_converstions:"Clicks vs Conversions",
                revenue_epc:"Revenue vs EPC"

            }
        },

        // offers content
        offersContent: {
            title: "Offers",
            filter: "Filter",

            table_headers: {
                name: "Name",
                price_format: "Price Format",
                avg_epc: "Avg EPC",
                payout: "Payout",
                countries: "Countries",
                categories: "Category",
                devices: "Devices",

            },

            offer_popup:{
                copy_to_clipboard:"Copy Link to Clipboard"

            }
        },


        // reports content
        reportsContent: {
            title: "Reports",
            traffic_report_for: "Traffic : ",
            apply: "Apply",
            date: "Date",

            table_headers: {
                offer: "Offer",
                source_medium: "Source/Medium",

                clicks: "Clicks",
                avg_epc: "Avg EPC",
                revenue: "Revenue",
                engagement_rate: "Engagement Rate",
                conversions: "Conversions",

            },

        },


        // tools content
        toolsContent: {
            title: "Tools",
            edit_code_title: "Edit Code",
            regenerate: "Regenerate",
            tracking_tags: " - Tracking Tags",
            before_head_tag: "Before </head> Tag",
            before_body_tag: "Before </body> Tag",

        },
    
       
        // billings content
        billingsContent: {
            title: "Billing & Payments",
            total_earnings: "Your Earnings so far",
            unpaid_balance: "Unpaid Balance",
            last_payment: "Last Payment on",

            earnings_by_month: "Earnings by Month",
            settings: "Settings",

            earnings_by_month_headers: {
                months: "Months",
                clicks: "Clicks",
                impression: "Impression",
                invalid_traffic_deduction: "Traffic Deduction",
                earned: "Earned",

            },
            settings_headers:{
                full_name: "Name",
                payment_method: "Method",
                update_date: "Updated",
                payment_cycle: "Payment Cycle"
            },

            billings_headers:{
                period: "Period",
                invoice_receipt_id: "Invoice/ReceiptID",
                payment_method: "Payment Method",
                payment_date: "Payment Date",
                transaction_amount: "Transaction Amount",
                status: "Status",
            }
        },


        // logs content
        logs: {
            title: "Logs",

            logs_headers:{
                type: "Type",
                by: "By",
                when: "When",
                details: "Details"
            }
            
        },
    }
}

export default NAMES;